import React from "react";
import "./App.css";
import styled, { keyframes } from "styled-components";
import Flow from "./features/flow/Flow";
import { Login } from "./features/login/Login";
import BottomBar from "./features/flow/BottomBar";
import theme from "./theme/theme";
import ColorPicker from "./features/flow/ColorPicker";
import Inputer from "./features/flow/Inputer";

function App() {
  const appLogoFloatAnimation = keyframes`
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0px);
    }
  `;

  const AppLogo = styled.img`
    height: 40vmin;
    pointer-events: none;
    animation: ${appLogoFloatAnimation} infinite 3s ease-in-out;
  `;

  return (
    <div className="App" style={{ color: "#343736", backgroundColor: "#F2F3F3", width: "100%", height: 800, color: `${theme.colors.Text}` }}>
      <div className="logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
          <g clip-path="url(#clip0_135_4944)">
            <path d="M4.13397 35.0794C6.4171 35.0794 8.26794 33.2133 8.26794 30.9115C8.26794 28.6097 6.4171 26.7437 4.13397 26.7437C1.85084 26.7437 0 28.6097 0 30.9115C0 33.2133 1.85084 35.0794 4.13397 35.0794Z" fill="#73D6CD" />
            <path d="M4.13397 34.8154C6.4171 34.8154 8.26794 32.9494 8.26794 30.6476C8.26794 28.3457 6.4171 26.4797 4.13397 26.4797C1.85084 26.4797 0 28.3457 0 30.6476C0 32.9494 1.85084 34.8154 4.13397 34.8154Z" fill="#73D6CD" />
            <path d="M4.13397 34.5515C6.4171 34.5515 8.26794 32.6855 8.26794 30.3837C8.26794 28.0818 6.4171 26.2158 4.13397 26.2158C1.85084 26.2158 0 28.0818 0 30.3837C0 32.6855 1.85084 34.5515 4.13397 34.5515Z" fill="#73D6CC" />
            <path d="M4.13397 34.2839C6.4171 34.2839 8.26794 32.4179 8.26794 30.1161C8.26794 27.8143 6.4171 25.9482 4.13397 25.9482C1.85084 25.9482 0 27.8143 0 30.1161C0 32.4179 1.85084 34.2839 4.13397 34.2839Z" fill="#73D6CC" />
            <path d="M4.13397 34.02C6.4171 34.02 8.26794 32.154 8.26794 29.8522C8.26794 27.5503 6.4171 25.6843 4.13397 25.6843C1.85084 25.6843 0 27.5503 0 29.8522C0 32.154 1.85084 34.02 4.13397 34.02Z" fill="#73D6CC" />
            <path d="M4.13397 33.7561C6.4171 33.7561 8.26794 31.8901 8.26794 29.5883C8.26794 27.2864 6.4171 25.4204 4.13397 25.4204C1.85084 25.4204 0 27.2864 0 29.5883C0 31.8901 1.85084 33.7561 4.13397 33.7561Z" fill="#73D6CC" />
            <path d="M4.13397 33.4922C6.4171 33.4922 8.26794 31.6262 8.26794 29.3243C8.26794 27.0225 6.4171 25.1565 4.13397 25.1565C1.85084 25.1565 0 27.0225 0 29.3243C0 31.6262 1.85084 33.4922 4.13397 33.4922Z" fill="#73D6CB" />
            <path d="M4.13397 33.2246C6.4171 33.2246 8.26794 31.3586 8.26794 29.0568C8.26794 26.7549 6.4171 24.8889 4.13397 24.8889C1.85084 24.8889 0 26.7549 0 29.0568C0 31.3586 1.85084 33.2246 4.13397 33.2246Z" fill="#73D6CB" />
            <path d="M4.13397 32.9607C6.4171 32.9607 8.26794 31.0947 8.26794 28.7929C8.26794 26.491 6.4171 24.625 4.13397 24.625C1.85084 24.625 0 26.491 0 28.7929C0 31.0947 1.85084 32.9607 4.13397 32.9607Z" fill="#73D6CB" />
            <path d="M4.13397 32.6968C6.4171 32.6968 8.26794 30.8308 8.26794 28.5289C8.26794 26.2271 6.4171 24.3611 4.13397 24.3611C1.85084 24.3611 0 26.2271 0 28.5289C0 30.8308 1.85084 32.6968 4.13397 32.6968Z" fill="#73D6CA" />
            <path d="M4.13397 32.4329C6.4171 32.4329 8.26794 30.5669 8.26794 28.265C8.26794 25.9632 6.4171 24.0972 4.13397 24.0972C1.85084 24.0972 0 25.9632 0 28.265C0 30.5669 1.85084 32.4329 4.13397 32.4329Z" fill="#73D6CA" />
            <path d="M4.13397 32.1653C6.4171 32.1653 8.26794 30.2993 8.26794 27.9974C8.26794 25.6956 6.4171 23.8296 4.13397 23.8296C1.85084 23.8296 0 25.6956 0 27.9974C0 30.2993 1.85084 32.1653 4.13397 32.1653Z" fill="#73D6CA" />
            <path d="M4.13397 31.9014C6.4171 31.9014 8.26794 30.0354 8.26794 27.7335C8.26794 25.4317 6.4171 23.5657 4.13397 23.5657C1.85084 23.5657 0 25.4317 0 27.7335C0 30.0354 1.85084 31.9014 4.13397 31.9014Z" fill="#73D6CA" />
            <path d="M4.13397 31.6375C6.4171 31.6375 8.26794 29.7715 8.26794 27.4696C8.26794 25.1678 6.4171 23.3018 4.13397 23.3018C1.85084 23.3018 0 25.1678 0 27.4696C0 29.7715 1.85084 31.6375 4.13397 31.6375Z" fill="#73D6C9" />
            <path d="M4.13397 31.3735C6.4171 31.3735 8.26794 29.5075 8.26794 27.2057C8.26794 24.9039 6.4171 23.0378 4.13397 23.0378C1.85084 23.0378 0 24.9039 0 27.2057C0 29.5075 1.85084 31.3735 4.13397 31.3735Z" fill="#73D6C9" />
            <path d="M4.13397 31.106C6.4171 31.106 8.26794 29.24 8.26794 26.9381C8.26794 24.6363 6.4171 22.7703 4.13397 22.7703C1.85084 22.7703 0 24.6363 0 26.9381C0 29.24 1.85084 31.106 4.13397 31.106Z" fill="#73D6C9" />
            <path d="M4.13397 30.8421C6.4171 30.8421 8.26794 28.976 8.26794 26.6742C8.26794 24.3724 6.4171 22.5063 4.13397 22.5063C1.85084 22.5063 0 24.3724 0 26.6742C0 28.976 1.85084 30.8421 4.13397 30.8421Z" fill="#73D6C8" />
            <path d="M4.13397 30.5781C6.4171 30.5781 8.26794 28.7121 8.26794 26.4103C8.26794 24.1084 6.4171 22.2424 4.13397 22.2424C1.85084 22.2424 0 24.1084 0 26.4103C0 28.7121 1.85084 30.5781 4.13397 30.5781Z" fill="#73D6C8" />
            <path d="M4.13397 30.3142C6.4171 30.3142 8.26794 28.4482 8.26794 26.1464C8.26794 23.8445 6.4171 21.9785 4.13397 21.9785C1.85084 21.9785 0 23.8445 0 26.1464C0 28.4482 1.85084 30.3142 4.13397 30.3142Z" fill="#73D6C8" />
            <path d="M4.13397 30.0466C6.4171 30.0466 8.26794 28.1806 8.26794 25.8788C8.26794 23.577 6.4171 21.7109 4.13397 21.7109C1.85084 21.7109 0 23.577 0 25.8788C0 28.1806 1.85084 30.0466 4.13397 30.0466Z" fill="#73D6C8" />
            <path d="M4.13397 29.7827C6.4171 29.7827 8.26794 27.9167 8.26794 25.6149C8.26794 23.313 6.4171 21.447 4.13397 21.447C1.85084 21.447 0 23.313 0 25.6149C0 27.9167 1.85084 29.7827 4.13397 29.7827Z" fill="#73D6C7" />
            <path d="M4.13397 29.5188C6.4171 29.5188 8.26794 27.6528 8.26794 25.351C8.26794 23.0491 6.4171 21.1831 4.13397 21.1831C1.85084 21.1831 0 23.0491 0 25.351C0 27.6528 1.85084 29.5188 4.13397 29.5188Z" fill="#73D6C7" />
            <path d="M4.13397 29.2549C6.4171 29.2549 8.26794 27.3889 8.26794 25.087C8.26794 22.7852 6.4171 20.9192 4.13397 20.9192C1.85084 20.9192 0 22.7852 0 25.087C0 27.3889 1.85084 29.2549 4.13397 29.2549Z" fill="#73D6C7" />
            <path d="M4.13397 28.991C6.4171 28.991 8.26794 27.125 8.26794 24.8231C8.26794 22.5213 6.4171 20.6553 4.13397 20.6553C1.85084 20.6553 0 22.5213 0 24.8231C0 27.125 1.85084 28.991 4.13397 28.991Z" fill="#73D6C6" />
            <path d="M4.13397 28.7234C6.4171 28.7234 8.26794 26.8574 8.26794 24.5555C8.26794 22.2537 6.4171 20.3877 4.13397 20.3877C1.85084 20.3877 0 22.2537 0 24.5555C0 26.8574 1.85084 28.7234 4.13397 28.7234Z" fill="#73D6C6" />
            <path d="M4.13397 28.4595C6.4171 28.4595 8.26794 26.5935 8.26794 24.2916C8.26794 21.9898 6.4171 20.1238 4.13397 20.1238C1.85084 20.1238 0 21.9898 0 24.2916C0 26.5935 1.85084 28.4595 4.13397 28.4595Z" fill="#73D6C6" />
            <path d="M4.13397 28.1956C6.4171 28.1956 8.26794 26.3296 8.26794 24.0277C8.26794 21.7259 6.4171 19.8599 4.13397 19.8599C1.85084 19.8599 0 21.7259 0 24.0277C0 26.3296 1.85084 28.1956 4.13397 28.1956Z" fill="#73D6C6" />
            <path d="M4.13397 27.9317C6.4171 27.9317 8.26794 26.0656 8.26794 23.7638C8.26794 21.462 6.4171 19.5959 4.13397 19.5959C1.85084 19.5959 0 21.462 0 23.7638C0 26.0656 1.85084 27.9317 4.13397 27.9317Z" fill="#73D6C5" />
            <path d="M4.13397 27.6641C6.4171 27.6641 8.26794 25.7981 8.26794 23.4962C8.26794 21.1944 6.4171 19.3284 4.13397 19.3284C1.85084 19.3284 0 21.1944 0 23.4962C0 25.7981 1.85084 27.6641 4.13397 27.6641Z" fill="#73D6C5" />
            <path d="M4.13397 27.4002C6.4171 27.4002 8.26794 25.5341 8.26794 23.2323C8.26794 20.9305 6.4171 19.0645 4.13397 19.0645C1.85084 19.0645 0 20.9305 0 23.2323C0 25.5341 1.85084 27.4002 4.13397 27.4002Z" fill="#73D6C5" />
            <path d="M4.13397 27.1362C6.4171 27.1362 8.26794 25.2702 8.26794 22.9684C8.26794 20.6665 6.4171 18.8005 4.13397 18.8005C1.85084 18.8005 0 20.6665 0 22.9684C0 25.2702 1.85084 27.1362 4.13397 27.1362Z" fill="#73D6C4" />
            <path d="M4.13397 26.8723C6.4171 26.8723 8.26794 25.0063 8.26794 22.7045C8.26794 20.4026 6.4171 18.5366 4.13397 18.5366C1.85084 18.5366 0 20.4026 0 22.7045C0 25.0063 1.85084 26.8723 4.13397 26.8723Z" fill="#73D6C4" />
            <path d="M4.13397 26.6048C6.4171 26.6048 8.26794 24.7387 8.26794 22.4369C8.26794 20.1351 6.4171 18.269 4.13397 18.269C1.85084 18.269 0 20.1351 0 22.4369C0 24.7387 1.85084 26.6048 4.13397 26.6048Z" fill="#73D6C4" />
            <path d="M4.13397 26.3408C6.4171 26.3408 8.26794 24.4748 8.26794 22.173C8.26794 19.8711 6.4171 18.0051 4.13397 18.0051C1.85084 18.0051 0 19.8711 0 22.173C0 24.4748 1.85084 26.3408 4.13397 26.3408Z" fill="#73D6C4" />
            <path d="M4.13397 26.0769C6.4171 26.0769 8.26794 24.2109 8.26794 21.9091C8.26794 19.6072 6.4171 17.7412 4.13397 17.7412C1.85084 17.7412 0 19.6072 0 21.9091C0 24.2109 1.85084 26.0769 4.13397 26.0769Z" fill="#73D6C3" />
            <path d="M4.13397 25.813C6.4171 25.813 8.26794 23.947 8.26794 21.6451C8.26794 19.3433 6.4171 17.4773 4.13397 17.4773C1.85084 17.4773 0 19.3433 0 21.6451C0 23.947 1.85084 25.813 4.13397 25.813Z" fill="#73D6C3" />
            <path d="M4.13397 25.5454C6.4171 25.5454 8.26794 23.6794 8.26794 21.3776C8.26794 19.0757 6.4171 17.2097 4.13397 17.2097C1.85084 17.2097 0 19.0757 0 21.3776C0 23.6794 1.85084 25.5454 4.13397 25.5454Z" fill="#73D6C3" />
            <path d="M4.13397 25.2815C6.4171 25.2815 8.26794 23.4155 8.26794 21.1137C8.26794 18.8118 6.4171 16.9458 4.13397 16.9458C1.85084 16.9458 0 18.8118 0 21.1137C0 23.4155 1.85084 25.2815 4.13397 25.2815Z" fill="#73D6C2" />
            <path d="M4.13397 25.0176C6.4171 25.0176 8.26794 23.1516 8.26794 20.8497C8.26794 18.5479 6.4171 16.6819 4.13397 16.6819C1.85084 16.6819 0 18.5479 0 20.8497C0 23.1516 1.85084 25.0176 4.13397 25.0176Z" fill="#73D6C2" />
            <path d="M4.13397 24.7537C6.4171 24.7537 8.26794 22.8877 8.26794 20.5858C8.26794 18.284 6.4171 16.418 4.13397 16.418C1.85084 16.418 0 18.284 0 20.5858C0 22.8877 1.85084 24.7537 4.13397 24.7537Z" fill="#73D6C2" />
            <path d="M4.13397 24.4861C6.4171 24.4861 8.26794 22.6201 8.26794 20.3182C8.26794 18.0164 6.4171 16.1504 4.13397 16.1504C1.85084 16.1504 0 18.0164 0 20.3182C0 22.6201 1.85084 24.4861 4.13397 24.4861Z" fill="#73D6C2" />
            <path d="M4.13397 24.2222C6.4171 24.2222 8.26794 22.3562 8.26794 20.0543C8.26794 17.7525 6.4171 15.8865 4.13397 15.8865C1.85084 15.8865 0 17.7525 0 20.0543C0 22.3562 1.85084 24.2222 4.13397 24.2222Z" fill="#73D6C1" />
            <path d="M4.13397 23.9583C6.4171 23.9583 8.26794 22.0923 8.26794 19.7904C8.26794 17.4886 6.4171 15.6226 4.13397 15.6226C1.85084 15.6226 0 17.4886 0 19.7904C0 22.0923 1.85084 23.9583 4.13397 23.9583Z" fill="#73D6C1" />
            <path d="M4.13397 23.6944C6.4171 23.6944 8.26794 21.8283 8.26794 19.5265C8.26794 17.2247 6.4171 15.3586 4.13397 15.3586C1.85084 15.3586 0 17.2247 0 19.5265C0 21.8283 1.85084 23.6944 4.13397 23.6944Z" fill="#73D6C1" />
            <path d="M4.13397 23.4302C6.4171 23.4302 8.26794 21.5642 8.26794 19.2623C8.26794 16.9605 6.4171 15.0945 4.13397 15.0945C1.85084 15.0945 0 16.9605 0 19.2623C0 21.5642 1.85084 23.4302 4.13397 23.4302Z" fill="#73D6C0" />
            <path d="M4.13397 23.1629C6.4171 23.1629 8.26794 21.2968 8.26794 18.995C8.26794 16.6932 6.4171 14.8271 4.13397 14.8271C1.85084 14.8271 0 16.6932 0 18.995C0 21.2968 1.85084 23.1629 4.13397 23.1629Z" fill="#73D6C0" />
            <path d="M4.13397 22.8989C6.4171 22.8989 8.26794 21.0329 8.26794 18.7311C8.26794 16.4292 6.4171 14.5632 4.13397 14.5632C1.85084 14.5632 0 16.4292 0 18.7311C0 21.0329 1.85084 22.8989 4.13397 22.8989Z" fill="#73D6C0" />
            <path d="M4.13397 22.635C6.4171 22.635 8.26794 20.769 8.26794 18.4672C8.26794 16.1653 6.4171 14.2993 4.13397 14.2993C1.85084 14.2993 0 16.1653 0 18.4672C0 20.769 1.85084 22.635 4.13397 22.635Z" fill="#73D6C0" />
            <path d="M4.13397 22.3709C6.4171 22.3709 8.26794 20.5049 8.26794 18.203C8.26794 15.9012 6.4171 14.0352 4.13397 14.0352C1.85084 14.0352 0 15.9012 0 18.203C0 20.5049 1.85084 22.3709 4.13397 22.3709Z" fill="#73D6BF" />
            <path d="M4.13397 22.1035C6.4171 22.1035 8.26794 20.2375 8.26794 17.9357C8.26794 15.6338 6.4171 13.7678 4.13397 13.7678C1.85084 13.7678 0 15.6338 0 17.9357C0 20.2375 1.85084 22.1035 4.13397 22.1035Z" fill="#73D6BF" />
            <path d="M4.13397 21.8396C6.4171 21.8396 8.26794 19.9736 8.26794 17.6718C8.26794 15.3699 6.4171 13.5039 4.13397 13.5039C1.85084 13.5039 0 15.3699 0 17.6718C0 19.9736 1.85084 21.8396 4.13397 21.8396Z" fill="#73D6BF" />
            <path d="M4.13397 21.5757C6.4171 21.5757 8.26794 19.7097 8.26794 17.4078C8.26794 15.106 6.4171 13.24 4.13397 13.24C1.85084 13.24 0 15.106 0 17.4078C0 19.7097 1.85084 21.5757 4.13397 21.5757Z" fill="#73D5BE" />
            <path d="M4.13397 21.3115C6.4171 21.3115 8.26794 19.4455 8.26794 17.1437C8.26794 14.8418 6.4171 12.9758 4.13397 12.9758C1.85084 12.9758 0 14.8418 0 17.1437C0 19.4455 1.85084 21.3115 4.13397 21.3115Z" fill="#73D5BE" />
            <path d="M4.13397 21.0442C6.4171 21.0442 8.26794 19.1782 8.26794 16.8763C8.26794 14.5745 6.4171 12.7085 4.13397 12.7085C1.85084 12.7085 0 14.5745 0 16.8763C0 19.1782 1.85084 21.0442 4.13397 21.0442Z" fill="#73D5BE" />
            <path d="M4.13397 20.7803C6.4171 20.7803 8.26794 18.9143 8.26794 16.6124C8.26794 14.3106 6.4171 12.4446 4.13397 12.4446C1.85084 12.4446 0 14.3106 0 16.6124C0 18.9143 1.85084 20.7803 4.13397 20.7803Z" fill="#73D5BD" />
            <path d="M4.13397 20.5164C6.4171 20.5164 8.26794 18.6504 8.26794 16.3485C8.26794 14.0467 6.4171 12.1807 4.13397 12.1807C1.85084 12.1807 0 14.0467 0 16.3485C0 18.6504 1.85084 20.5164 4.13397 20.5164Z" fill="#73D5BD" />
            <path d="M4.13397 20.2522C6.4171 20.2522 8.26794 18.3862 8.26794 16.0844C8.26794 13.7825 6.4171 11.9165 4.13397 11.9165C1.85084 11.9165 0 13.7825 0 16.0844C0 18.3862 1.85084 20.2522 4.13397 20.2522Z" fill="#73D5BD" />
            <path d="M4.13397 19.9849C6.4171 19.9849 8.26794 18.1189 8.26794 15.817C8.26794 13.5152 6.4171 11.6492 4.13397 11.6492C1.85084 11.6492 0 13.5152 0 15.817C0 18.1189 1.85084 19.9849 4.13397 19.9849Z" fill="#73D5BD" />
            <path d="M4.13397 19.721C6.4171 19.721 8.26794 17.8549 8.26794 15.5531C8.26794 13.2513 6.4171 11.3853 4.13397 11.3853C1.85084 11.3853 0 13.2513 0 15.5531C0 17.8549 1.85084 19.721 4.13397 19.721Z" fill="#73D5BC" />
            <path d="M4.13397 19.457C6.4171 19.457 8.26794 17.591 8.26794 15.2892C8.26794 12.9873 6.4171 11.1213 4.13397 11.1213C1.85084 11.1213 0 12.9873 0 15.2892C0 17.591 1.85084 19.457 4.13397 19.457Z" fill="#73D5BC" />
            <path d="M4.13397 19.1929C6.4171 19.1929 8.26794 17.3269 8.26794 15.025C8.26794 12.7232 6.4171 10.8572 4.13397 10.8572C1.85084 10.8572 0 12.7232 0 15.025C0 17.3269 1.85084 19.1929 4.13397 19.1929Z" fill="#73D5BC" />
            <path d="M4.13397 18.929C6.4171 18.929 8.26794 17.063 8.26794 14.7611C8.26794 12.4593 6.4171 10.5933 4.13397 10.5933C1.85084 10.5933 0 12.4593 0 14.7611C0 17.063 1.85084 18.929 4.13397 18.929Z" fill="#73D5BB" />
            <path d="M4.13397 18.6616C6.4171 18.6616 8.26794 16.7956 8.26794 14.4938C8.26794 12.1919 6.4171 10.3259 4.13397 10.3259C1.85084 10.3259 0 12.1919 0 14.4938C0 16.7956 1.85084 18.6616 4.13397 18.6616Z" fill="#73D5BB" />
            <path d="M4.13397 18.3977C6.4171 18.3977 8.26794 16.5317 8.26794 14.2299C8.26794 11.928 6.4171 10.062 4.13397 10.062C1.85084 10.062 0 11.928 0 14.2299C0 16.5317 1.85084 18.3977 4.13397 18.3977Z" fill="#73D5BB" />
            <path d="M4.13397 18.1336C6.4171 18.1336 8.26794 16.2675 8.26794 13.9657C8.26794 11.6639 6.4171 9.79785 4.13397 9.79785C1.85084 9.79785 0 11.6639 0 13.9657C0 16.2675 1.85084 18.1336 4.13397 18.1336Z" fill="#73D5BB" />
            <path d="M4.13397 17.8696C6.4171 17.8696 8.26794 16.0036 8.26794 13.7018C8.26794 11.3999 6.4171 9.53394 4.13397 9.53394C1.85084 9.53394 0 11.3999 0 13.7018C0 16.0036 1.85084 17.8696 4.13397 17.8696Z" fill="#73D5BA" />
            <path d="M4.13397 17.6023C6.4171 17.6023 8.26794 15.7363 8.26794 13.4345C8.26794 11.1326 6.4171 9.2666 4.13397 9.2666C1.85084 9.2666 0 11.1326 0 13.4345C0 15.7363 1.85084 17.6023 4.13397 17.6023Z" fill="#73D5BA" />
            <path d="M4.13397 17.3384C6.4171 17.3384 8.26794 15.4724 8.26794 13.1705C8.26794 10.8687 6.4171 9.00269 4.13397 9.00269C1.85084 9.00269 0 10.8687 0 13.1705C0 15.4724 1.85084 17.3384 4.13397 17.3384Z" fill="#73D5BA" />
            <path d="M4.13397 17.0742C6.4171 17.0742 8.26794 15.2082 8.26794 12.9064C8.26794 10.6045 6.4171 8.73853 4.13397 8.73853C1.85084 8.73853 0 10.6045 0 12.9064C0 15.2082 1.85084 17.0742 4.13397 17.0742Z" fill="#73D5B9" />
            <path d="M4.13397 16.8103C6.4171 16.8103 8.26794 14.9443 8.26794 12.6425C8.26794 10.3406 6.4171 8.47461 4.13397 8.47461C1.85084 8.47461 0 10.3406 0 12.6425C0 14.9443 1.85084 16.8103 4.13397 16.8103Z" fill="#73D5B9" />
            <path d="M4.13397 16.543C6.4171 16.543 8.26794 14.677 8.26794 12.3751C8.26794 10.0733 6.4171 8.20728 4.13397 8.20728C1.85084 8.20728 0 10.0733 0 12.3751C0 14.677 1.85084 16.543 4.13397 16.543Z" fill="#73D5B9" />
            <path d="M4.13397 16.2788C6.4171 16.2788 8.26794 14.4128 8.26794 12.111C8.26794 9.80913 6.4171 7.94312 4.13397 7.94312C1.85084 7.94312 0 9.80913 0 12.111C0 14.4128 1.85084 16.2788 4.13397 16.2788Z" fill="#73D5B9" />
            <path d="M4.13397 16.0149C6.4171 16.0149 8.26794 14.1489 8.26794 11.8471C8.26794 9.54521 6.4171 7.6792 4.13397 7.6792C1.85084 7.6792 0 9.54521 0 11.8471C0 14.1489 1.85084 16.0149 4.13397 16.0149Z" fill="#73D5B8" />
            <path d="M4.13397 15.751C6.4171 15.751 8.26794 13.885 8.26794 11.5831C8.26794 9.28129 6.4171 7.41528 4.13397 7.41528C1.85084 7.41528 0 9.28129 0 11.5831C0 13.885 1.85084 15.751 4.13397 15.751Z" fill="#73D5B8" />
            <path d="M4.13397 15.4837C6.4171 15.4837 8.26794 13.6176 8.26794 11.3158C8.26794 9.01396 6.4171 7.14795 4.13397 7.14795C1.85084 7.14795 0 9.01396 0 11.3158C0 13.6176 1.85084 15.4837 4.13397 15.4837Z" fill="#73D5B8" />
            <path d="M4.13397 15.2195C6.4171 15.2195 8.26794 13.3535 8.26794 11.0516C8.26794 8.7498 6.4171 6.88379 4.13397 6.88379C1.85084 6.88379 0 8.7498 0 11.0516C0 13.3535 1.85084 15.2195 4.13397 15.2195Z" fill="#73D5B7" />
            <path d="M4.13397 14.9556C6.4171 14.9556 8.26794 13.0896 8.26794 10.7877C8.26794 8.48588 6.4171 6.61987 4.13397 6.61987C1.85084 6.61987 0 8.48588 0 10.7877C0 13.0896 1.85084 14.9556 4.13397 14.9556Z" fill="#73D5B7" />
            <path d="M4.13397 14.6917C6.4171 14.6917 8.26794 12.8257 8.26794 10.5238C8.26794 8.22197 6.4171 6.35596 4.13397 6.35596C1.85084 6.35596 0 8.22197 0 10.5238C0 12.8257 1.85084 14.6917 4.13397 14.6917Z" fill="#73D5B7" />
            <path d="M4.13397 14.4243C6.4171 14.4243 8.26794 12.5583 8.26794 10.2565C8.26794 7.95463 6.4171 6.08862 4.13397 6.08862C1.85084 6.08862 0 7.95463 0 10.2565C0 12.5583 1.85084 14.4243 4.13397 14.4243Z" fill="#73D5B7" />
            <path d="M4.13397 14.1602C6.4171 14.1602 8.26794 12.2942 8.26794 9.99232C8.26794 7.69047 6.4171 5.82446 4.13397 5.82446C1.85084 5.82446 0 7.69047 0 9.99232C0 12.2942 1.85084 14.1602 4.13397 14.1602Z" fill="#73D5B6" />
            <path d="M4.13397 13.8963C6.4171 13.8963 8.26794 12.0302 8.26794 9.7284C8.26794 7.42656 6.4171 5.56055 4.13397 5.56055C1.85084 5.56055 0 7.42656 0 9.7284C0 12.0302 1.85084 13.8963 4.13397 13.8963Z" fill="#73D5B6" />
            <path d="M4.13397 13.6323C6.4171 13.6323 8.26794 11.7663 8.26794 9.46448C8.26794 7.16264 6.4171 5.29663 4.13397 5.29663C1.85084 5.29663 0 7.16264 0 9.46448C0 11.7663 1.85084 13.6323 4.13397 13.6323Z" fill="#73D5B6" />
            <path d="M4.13397 13.3684C6.4171 13.3684 8.26794 11.5024 8.26794 9.20057C8.26794 6.89873 6.4171 5.03271 4.13397 5.03271C1.85084 5.03271 0 6.89873 0 9.20057C0 11.5024 1.85084 13.3684 4.13397 13.3684Z" fill="#73D5B5" />
            <path d="M4.13397 13.1008C6.4171 13.1008 8.26794 11.2348 8.26794 8.93299C8.26794 6.63115 6.4171 4.76514 4.13397 4.76514C1.85084 4.76514 0 6.63115 0 8.93299C0 11.2348 1.85084 13.1008 4.13397 13.1008Z" fill="#73D5B5" />
            <path d="M4.13397 12.8369C6.4171 12.8369 8.26794 10.9709 8.26794 8.66907C8.26794 6.36723 6.4171 4.50122 4.13397 4.50122C1.85084 4.50122 0 6.36723 0 8.66907C0 10.9709 1.85084 12.8369 4.13397 12.8369Z" fill="#73D5B5" />
            <path d="M4.13397 12.573C6.4171 12.573 8.26794 10.707 8.26794 8.40516C8.26794 6.10332 6.4171 4.2373 4.13397 4.2373C1.85084 4.2373 0 6.10332 0 8.40516C0 10.707 1.85084 12.573 4.13397 12.573Z" fill="#73D5B5" />
            <path d="M4.13397 12.3091C6.4171 12.3091 8.26794 10.4431 8.26794 8.14124C8.26794 5.8394 6.4171 3.97339 4.13397 3.97339C1.85084 3.97339 0 5.8394 0 8.14124C0 10.4431 1.85084 12.3091 4.13397 12.3091Z" fill="#73D5B4" />
            <path d="M4.13397 12.0415C6.4171 12.0415 8.26794 10.1755 8.26794 7.87366C8.26794 5.57182 6.4171 3.70581 4.13397 3.70581C1.85084 3.70581 0 5.57182 0 7.87366C0 10.1755 1.85084 12.0415 4.13397 12.0415Z" fill="#73D5B4" />
            <path d="M4.13397 11.7776C6.4171 11.7776 8.26794 9.91159 8.26794 7.60975C8.26794 5.30791 6.4171 3.44189 4.13397 3.44189C1.85084 3.44189 0 5.30791 0 7.60975C0 9.91159 1.85084 11.7776 4.13397 11.7776Z" fill="#73D5B4" />
            <path d="M4.13397 11.5137C6.4171 11.5137 8.26794 9.64767 8.26794 7.34583C8.26794 5.04399 6.4171 3.17798 4.13397 3.17798C1.85084 3.17798 0 5.04399 0 7.34583C0 9.64767 1.85084 11.5137 4.13397 11.5137Z" fill="#73D5B3" />
            <path d="M4.13397 11.2498C6.4171 11.2498 8.26794 9.38376 8.26794 7.08192C8.26794 4.78007 6.4171 2.91406 4.13397 2.91406C1.85084 2.91406 0 4.78007 0 7.08192C0 9.38376 1.85084 11.2498 4.13397 11.2498Z" fill="#73D5B3" />
            <path d="M4.13397 10.9822C6.4171 10.9822 8.26794 9.11618 8.26794 6.81434C8.26794 4.5125 6.4171 2.64648 4.13397 2.64648C1.85084 2.64648 0 4.5125 0 6.81434C0 9.11618 1.85084 10.9822 4.13397 10.9822Z" fill="#73D5B3" />
            <path d="M4.13397 10.7183C6.4171 10.7183 8.26794 8.85226 8.26794 6.55042C8.26794 4.24858 6.4171 2.38257 4.13397 2.38257C1.85084 2.38257 0 4.24858 0 6.55042C0 8.85226 1.85084 10.7183 4.13397 10.7183Z" fill="#73D5B3" />
            <path d="M4.13397 10.4544C6.4171 10.4544 8.26794 8.58835 8.26794 6.28651C8.26794 3.98466 6.4171 2.11865 4.13397 2.11865C1.85084 2.11865 0 3.98466 0 6.28651C0 8.58835 1.85084 10.4544 4.13397 10.4544Z" fill="#73D5B2" />
            <path d="M4.13397 10.1904C6.4171 10.1904 8.26794 8.32443 8.26794 6.02259C8.26794 3.72075 6.4171 1.85474 4.13397 1.85474C1.85084 1.85474 0 3.72075 0 6.02259C0 8.32443 1.85084 10.1904 4.13397 10.1904Z" fill="#73D5B2" />
            <path d="M4.13397 9.92287C6.4171 9.92287 8.26794 8.05685 8.26794 5.75501C8.26794 3.45317 6.4171 1.58716 4.13397 1.58716C1.85084 1.58716 0 3.45317 0 5.75501C0 8.05685 1.85084 9.92287 4.13397 9.92287Z" fill="#73D5B2" />
            <path d="M4.13397 9.65895C6.4171 9.65895 8.26794 7.79294 8.26794 5.4911C8.26794 3.18925 6.4171 1.32324 4.13397 1.32324C1.85084 1.32324 0 3.18925 0 5.4911C0 7.79294 1.85084 9.65895 4.13397 9.65895Z" fill="#73D5B1" />
            <path d="M4.13397 9.39503C6.4171 9.39503 8.26794 7.52902 8.26794 5.22718C8.26794 2.92534 6.4171 1.05933 4.13397 1.05933C1.85084 1.05933 0 2.92534 0 5.22718C0 7.52902 1.85084 9.39503 4.13397 9.39503Z" fill="#73D5B1" />
            <path d="M4.13397 9.13112C6.4171 9.13112 8.26794 7.26511 8.26794 4.96326C8.26794 2.66142 6.4171 0.79541 4.13397 0.79541C1.85084 0.79541 0 2.66142 0 4.96326C0 7.26511 1.85084 9.13112 4.13397 9.13112Z" fill="#73D5B1" />
            <path d="M4.13397 8.86354C6.4171 8.86354 8.26794 6.99753 8.26794 4.69569C8.26794 2.39384 6.4171 0.527832 4.13397 0.527832C1.85084 0.527832 0 2.39384 0 4.69569C0 6.99753 1.85084 8.86354 4.13397 8.86354Z" fill="#73D5B1" />
            <path d="M4.13397 8.59962C6.4171 8.59962 8.26794 6.73361 8.26794 4.43177C8.26794 2.12993 6.4171 0.263916 4.13397 0.263916C1.85084 0.263916 0 2.12993 0 4.43177C0 6.73361 1.85084 8.59962 4.13397 8.59962Z" fill="#73D5B0" />
            <path d="M4.13397 8.33571C6.4171 8.33571 8.26794 6.4697 8.26794 4.16785C8.26794 1.86601 6.4171 0 4.13397 0C1.85084 0 0 1.86601 0 4.16785C0 6.4697 1.85084 8.33571 4.13397 8.33571Z" fill="#73D5B0" />
            <path d="M31.8659 35.0794C34.149 35.0794 35.9999 33.2133 35.9999 30.9115C35.9999 28.6097 34.149 26.7437 31.8659 26.7437C29.5828 26.7437 27.7319 28.6097 27.7319 30.9115C27.7319 33.2133 29.5828 35.0794 31.8659 35.0794Z" fill="#73D6CD" />
            <path d="M31.8659 34.4714C34.149 34.4714 35.9999 32.6054 35.9999 30.3036C35.9999 28.0018 34.149 26.1357 31.8659 26.1357C29.5828 26.1357 27.7319 28.0018 27.7319 30.3036C27.7319 32.6054 29.5828 34.4714 31.8659 34.4714Z" fill="#73D6CD" />
            <path d="M31.8659 33.8672C34.149 33.8672 35.9999 32.0012 35.9999 29.6993C35.9999 27.3975 34.149 25.5315 31.8659 25.5315C29.5828 25.5315 27.7319 27.3975 27.7319 29.6993C27.7319 32.0012 29.5828 33.8672 31.8659 33.8672Z" fill="#73D6CC" />
            <path d="M31.8659 33.2593C34.149 33.2593 35.9999 31.3933 35.9999 29.0914C35.9999 26.7896 34.149 24.9236 31.8659 24.9236C29.5828 24.9236 27.7319 26.7896 27.7319 29.0914C27.7319 31.3933 29.5828 33.2593 31.8659 33.2593Z" fill="#73D6CC" />
            <path d="M31.8659 32.655C34.149 32.655 35.9999 30.789 35.9999 28.4872C35.9999 26.1853 34.149 24.3193 31.8659 24.3193C29.5828 24.3193 27.7319 26.1853 27.7319 28.4872C27.7319 30.789 29.5828 32.655 31.8659 32.655Z" fill="#73D6CC" />
            <path d="M31.8659 32.0471C34.149 32.0471 35.9999 30.1811 35.9999 27.8793C35.9999 25.5774 34.149 23.7114 31.8659 23.7114C29.5828 23.7114 27.7319 25.5774 27.7319 27.8793C27.7319 30.1811 29.5828 32.0471 31.8659 32.0471Z" fill="#73D6CC" />
            <path d="M31.8659 31.4429C34.149 31.4429 35.9999 29.5769 35.9999 27.275C35.9999 24.9732 34.149 23.1072 31.8659 23.1072C29.5828 23.1072 27.7319 24.9732 27.7319 27.275C27.7319 29.5769 29.5828 31.4429 31.8659 31.4429Z" fill="#73D6CB" />
            <path d="M31.8659 30.835C34.149 30.835 35.9999 28.969 35.9999 26.6671C35.9999 24.3653 34.149 22.4993 31.8659 22.4993C29.5828 22.4993 27.7319 24.3653 27.7319 26.6671C27.7319 28.969 29.5828 30.835 31.8659 30.835Z" fill="#73D6CB" />
            <path d="M31.8659 30.2273C34.149 30.2273 35.9999 28.3613 35.9999 26.0595C35.9999 23.7576 34.149 21.8916 31.8659 21.8916C29.5828 21.8916 27.7319 23.7576 27.7319 26.0595C27.7319 28.3613 29.5828 30.2273 31.8659 30.2273Z" fill="#73D6CB" />
            <path d="M31.8659 29.6228C34.149 29.6228 35.9999 27.7568 35.9999 25.455C35.9999 23.1531 34.149 21.2871 31.8659 21.2871C29.5828 21.2871 27.7319 23.1531 27.7319 25.455C27.7319 27.7568 29.5828 29.6228 31.8659 29.6228Z" fill="#73D6CA" />
            <path d="M31.8659 29.0152C34.149 29.0152 35.9999 27.1491 35.9999 24.8473C35.9999 22.5455 34.149 20.6794 31.8659 20.6794C29.5828 20.6794 27.7319 22.5455 27.7319 24.8473C27.7319 27.1491 29.5828 29.0152 31.8659 29.0152Z" fill="#73D6CA" />
            <path d="M31.8659 28.4109C34.149 28.4109 35.9999 26.5449 35.9999 24.2431C35.9999 21.9412 34.149 20.0752 31.8659 20.0752C29.5828 20.0752 27.7319 21.9412 27.7319 24.2431C27.7319 26.5449 29.5828 28.4109 31.8659 28.4109Z" fill="#73D6CA" />
            <path d="M31.8659 27.803C34.149 27.803 35.9999 25.937 35.9999 23.6351C35.9999 21.3333 34.149 19.4673 31.8659 19.4673C29.5828 19.4673 27.7319 21.3333 27.7319 23.6351C27.7319 25.937 29.5828 27.803 31.8659 27.803Z" fill="#73D6CA" />
            <path d="M31.8659 27.1987C34.149 27.1987 35.9999 25.3327 35.9999 23.0309C35.9999 20.729 34.149 18.863 31.8659 18.863C29.5828 18.863 27.7319 20.729 27.7319 23.0309C27.7319 25.3327 29.5828 27.1987 31.8659 27.1987Z" fill="#73D6C9" />
            <path d="M31.8659 26.5908C34.149 26.5908 35.9999 24.7248 35.9999 22.423C35.9999 20.1211 34.149 18.2551 31.8659 18.2551C29.5828 18.2551 27.7319 20.1211 27.7319 22.423C27.7319 24.7248 29.5828 26.5908 31.8659 26.5908Z" fill="#73D6C9" />
            <path d="M31.8659 25.9829C34.149 25.9829 35.9999 24.1169 35.9999 21.8151C35.9999 19.5132 34.149 17.6472 31.8659 17.6472C29.5828 17.6472 27.7319 19.5132 27.7319 21.8151C27.7319 24.1169 29.5828 25.9829 31.8659 25.9829Z" fill="#73D6C9" />
            <path d="M31.8659 25.3787C34.149 25.3787 35.9999 23.5127 35.9999 21.2108C35.9999 18.909 34.149 17.043 31.8659 17.043C29.5828 17.043 27.7319 18.909 27.7319 21.2108C27.7319 23.5127 29.5828 25.3787 31.8659 25.3787Z" fill="#73D6C8" />
            <path d="M31.8659 24.7708C34.149 24.7708 35.9999 22.9048 35.9999 20.6029C35.9999 18.3011 34.149 16.4351 31.8659 16.4351C29.5828 16.4351 27.7319 18.3011 27.7319 20.6029C27.7319 22.9048 29.5828 24.7708 31.8659 24.7708Z" fill="#73D6C8" />
            <path d="M31.8659 24.1665C34.149 24.1665 35.9999 22.3005 35.9999 19.9987C35.9999 17.6968 34.149 15.8308 31.8659 15.8308C29.5828 15.8308 27.7319 17.6968 27.7319 19.9987C27.7319 22.3005 29.5828 24.1665 31.8659 24.1665Z" fill="#73D6C8" />
            <path d="M31.8659 23.5586C34.149 23.5586 35.9999 21.6926 35.9999 19.3908C35.9999 17.0889 34.149 15.2229 31.8659 15.2229C29.5828 15.2229 27.7319 17.0889 27.7319 19.3908C27.7319 21.6926 29.5828 23.5586 31.8659 23.5586Z" fill="#73D6C8" />
            <path d="M31.8659 22.9544C34.149 22.9544 35.9999 21.0883 35.9999 18.7865C35.9999 16.4847 34.149 14.6187 31.8659 14.6187C29.5828 14.6187 27.7319 16.4847 27.7319 18.7865C27.7319 21.0883 29.5828 22.9544 31.8659 22.9544Z" fill="#73D6C7" />
            <path d="M31.8659 22.3464C34.149 22.3464 35.9999 20.4804 35.9999 18.1786C35.9999 15.8768 34.149 14.0107 31.8659 14.0107C29.5828 14.0107 27.7319 15.8768 27.7319 18.1786C27.7319 20.4804 29.5828 22.3464 31.8659 22.3464Z" fill="#73D6C7" />
            <path d="M31.8659 21.7388C34.149 21.7388 35.9999 19.8728 35.9999 17.5709C35.9999 15.2691 34.149 13.4031 31.8659 13.4031C29.5828 13.4031 27.7319 15.2691 27.7319 17.5709C27.7319 19.8728 29.5828 21.7388 31.8659 21.7388Z" fill="#73D6C7" />
            <path d="M31.8659 21.1343C34.149 21.1343 35.9999 19.2683 35.9999 16.9664C35.9999 14.6646 34.149 12.7986 31.8659 12.7986C29.5828 12.7986 27.7319 14.6646 27.7319 16.9664C27.7319 19.2683 29.5828 21.1343 31.8659 21.1343Z" fill="#73D6C6" />
            <path d="M31.8659 20.5266C34.149 20.5266 35.9999 18.6606 35.9999 16.3588C35.9999 14.0569 34.149 12.1909 31.8659 12.1909C29.5828 12.1909 27.7319 14.0569 27.7319 16.3588C27.7319 18.6606 29.5828 20.5266 31.8659 20.5266Z" fill="#73D6C6" />
            <path d="M31.8659 19.9224C34.149 19.9224 35.9999 18.0564 35.9999 15.7545C35.9999 13.4527 34.149 11.5867 31.8659 11.5867C29.5828 11.5867 27.7319 13.4527 27.7319 15.7545C27.7319 18.0564 29.5828 19.9224 31.8659 19.9224Z" fill="#73D6C6" />
            <path d="M31.8659 19.3145C34.149 19.3145 35.9999 17.4485 35.9999 15.1466C35.9999 12.8448 34.149 10.9788 31.8659 10.9788C29.5828 10.9788 27.7319 12.8448 27.7319 15.1466C27.7319 17.4485 29.5828 19.3145 31.8659 19.3145Z" fill="#73D6C6" />
            <path d="M31.8659 18.7102C34.149 18.7102 35.9999 16.8442 35.9999 14.5424C35.9999 12.2405 34.149 10.3745 31.8659 10.3745C29.5828 10.3745 27.7319 12.2405 27.7319 14.5424C27.7319 16.8442 29.5828 18.7102 31.8659 18.7102Z" fill="#73D6C5" />
            <path d="M31.8659 18.1023C34.149 18.1023 35.9999 16.2363 35.9999 13.9345C35.9999 11.6326 34.149 9.7666 31.8659 9.7666C29.5828 9.7666 27.7319 11.6326 27.7319 13.9345C27.7319 16.2363 29.5828 18.1023 31.8659 18.1023Z" fill="#73D6C5" />
            <path d="M31.8659 17.4944C34.149 17.4944 35.9999 15.6284 35.9999 13.3265C35.9999 11.0247 34.149 9.15869 31.8659 9.15869C29.5828 9.15869 27.7319 11.0247 27.7319 13.3265C27.7319 15.6284 29.5828 17.4944 31.8659 17.4944Z" fill="#73D6C5" />
            <path d="M31.8659 16.8902C34.149 16.8902 35.9999 15.0241 35.9999 12.7223C35.9999 10.4205 34.149 8.55444 31.8659 8.55444C29.5828 8.55444 27.7319 10.4205 27.7319 12.7223C27.7319 15.0241 29.5828 16.8902 31.8659 16.8902Z" fill="#73D6C4" />
            <path d="M31.8659 16.2822C34.149 16.2822 35.9999 14.4162 35.9999 12.1144C35.9999 9.81255 34.149 7.94653 31.8659 7.94653C29.5828 7.94653 27.7319 9.81255 27.7319 12.1144C27.7319 14.4162 29.5828 16.2822 31.8659 16.2822Z" fill="#73D6C4" />
            <path d="M31.8659 15.678C34.149 15.678 35.9999 13.812 35.9999 11.5101C35.9999 9.2083 34.149 7.34229 31.8659 7.34229C29.5828 7.34229 27.7319 9.2083 27.7319 11.5101C27.7319 13.812 29.5828 15.678 31.8659 15.678Z" fill="#73D6C4" />
            <path d="M31.8591 15.0564C34.1422 15.0564 35.993 13.1904 35.993 10.8886C35.993 8.58671 34.1422 6.7207 31.8591 6.7207C29.5759 6.7207 27.7251 8.58671 27.7251 10.8886C27.7251 13.1904 29.5759 15.0564 31.8591 15.0564Z" fill="#73D6C4" />
            <path d="M31.8039 14.4312C34.087 14.4312 35.9379 12.5652 35.9379 10.2633C35.9379 7.96147 34.087 6.09546 31.8039 6.09546C29.5208 6.09546 27.6699 7.96147 27.6699 10.2633C27.6699 12.5652 29.5208 14.4312 31.8039 14.4312Z" fill="#73D6C3" />
            <path d="M31.6938 13.8233C33.9769 13.8233 35.8278 11.9572 35.8278 9.6554C35.8278 7.35356 33.9769 5.48755 31.6938 5.48755C29.4107 5.48755 27.5598 7.35356 27.5598 9.6554C27.5598 11.9572 29.4107 13.8233 31.6938 13.8233Z" fill="#73D6C3" />
            <path d="M31.5353 13.2363C33.8185 13.2363 35.6693 11.3703 35.6693 9.06849C35.6693 6.76665 33.8185 4.90063 31.5353 4.90063C29.2522 4.90063 27.4014 6.76665 27.4014 9.06849C27.4014 11.3703 29.2522 13.2363 31.5353 13.2363Z" fill="#73D6C3" />
            <path d="M31.3251 12.6702C33.6083 12.6702 35.4591 10.8042 35.4591 8.50233C35.4591 6.20048 33.6083 4.33447 31.3251 4.33447C29.042 4.33447 27.1912 6.20048 27.1912 8.50233C27.1912 10.8042 29.042 12.6702 31.3251 12.6702Z" fill="#73D6C2" />
            <path d="M31.0703 12.1284C33.3534 12.1284 35.2042 10.2624 35.2042 7.96058C35.2042 5.65874 33.3534 3.79272 31.0703 3.79272C28.7871 3.79272 26.9363 5.65874 26.9363 7.96058C26.9363 10.2624 28.7871 12.1284 31.0703 12.1284Z" fill="#73D6C2" />
            <path d="M30.7704 11.6143C33.0536 11.6143 34.9044 9.74826 34.9044 7.44642C34.9044 5.14458 33.0536 3.27856 30.7704 3.27856C28.4873 3.27856 26.6365 5.14458 26.6365 7.44642C26.6365 9.74826 28.4873 11.6143 30.7704 11.6143Z" fill="#73D6C2" />
            <path d="M30.4294 11.1282C32.7125 11.1282 34.5634 9.26218 34.5634 6.96033C34.5634 4.65849 32.7125 2.79248 30.4294 2.79248C28.1463 2.79248 26.2954 4.65849 26.2954 6.96033C26.2954 9.26218 28.1463 11.1282 30.4294 11.1282Z" fill="#73D6C2" />
            <path d="M30.0505 10.6765C32.3336 10.6765 34.1844 8.81052 34.1844 6.50867C34.1844 4.20683 32.3336 2.34082 30.0505 2.34082C27.7673 2.34082 25.9165 4.20683 25.9165 6.50867C25.9165 8.81052 27.7673 10.6765 30.0505 10.6765Z" fill="#73D6C1" />
            <path d="M29.6371 10.2564C31.9203 10.2564 33.7711 8.39035 33.7711 6.08851C33.7711 3.78667 31.9203 1.92065 29.6371 1.92065C27.354 1.92065 25.5032 3.78667 25.5032 6.08851C25.5032 8.39035 27.354 10.2564 29.6371 10.2564Z" fill="#73D6C1" />
            <path d="M29.1857 9.87428C31.4689 9.87428 33.3197 8.00827 33.3197 5.70643C33.3197 3.40459 31.4689 1.53857 29.1857 1.53857C26.9026 1.53857 25.0518 3.40459 25.0518 5.70643C25.0518 8.00827 26.9026 9.87428 29.1857 9.87428Z" fill="#73D6C1" />
            <path d="M28.707 9.53053C30.9901 9.53053 32.8409 7.66452 32.8409 5.36268C32.8409 3.06084 30.9901 1.19482 28.707 1.19482C26.4238 1.19482 24.573 3.06084 24.573 5.36268C24.573 7.66452 26.4238 9.53053 28.707 9.53053Z" fill="#73D6C0" />
            <path d="M28.1938 9.2317C30.4769 9.2317 32.3278 7.36569 32.3278 5.06385C32.3278 2.76201 30.4769 0.895996 28.1938 0.895996C25.9107 0.895996 24.0598 2.76201 24.0598 5.06385C24.0598 7.36569 25.9107 9.2317 28.1938 9.2317Z" fill="#73D6C0" />
            <path d="M27.6596 8.97121C29.9427 8.97121 31.7936 7.10519 31.7936 4.80335C31.7936 2.50151 29.9427 0.635498 27.6596 0.635498C25.3765 0.635498 23.5256 2.50151 23.5256 4.80335C23.5256 7.10519 25.3765 8.97121 27.6596 8.97121Z" fill="#73D6C0" />
            <path d="M27.0981 8.76271C29.3812 8.76271 31.2321 6.8967 31.2321 4.59486C31.2321 2.29301 29.3812 0.427002 27.0981 0.427002C24.815 0.427002 22.9641 2.29301 22.9641 4.59486C22.9641 6.8967 24.815 8.76271 27.0981 8.76271Z" fill="#73D6C0" />
            <path d="M26.5126 8.59962C28.7958 8.59962 30.6466 6.73361 30.6466 4.43177C30.6466 2.12993 28.7958 0.263916 26.5126 0.263916C24.2295 0.263916 22.3787 2.12993 22.3787 4.43177C22.3787 6.73361 24.2295 8.59962 26.5126 8.59962Z" fill="#73D6BF" />
            <path d="M25.9096 8.49196C28.1927 8.49196 30.0436 6.62595 30.0436 4.3241C30.0436 2.02226 28.1927 0.15625 25.9096 0.15625C23.6265 0.15625 21.7756 2.02226 21.7756 4.3241C21.7756 6.62595 23.6265 8.49196 25.9096 8.49196Z" fill="#73D6BF" />
            <path d="M25.2929 8.43288C27.576 8.43288 29.4269 6.56686 29.4269 4.26502C29.4269 1.96318 27.576 0.097168 25.2929 0.097168C23.0098 0.097168 21.1589 1.96318 21.1589 4.26502C21.1589 6.56686 23.0098 8.43288 25.2929 8.43288Z" fill="#73D6BF" />
            <path d="M24.6591 8.43288C26.9422 8.43288 28.7931 6.56686 28.7931 4.26502C28.7931 1.96318 26.9422 0.097168 24.6591 0.097168C22.376 0.097168 20.5251 1.96318 20.5251 4.26502C20.5251 6.56686 22.376 8.43288 24.6591 8.43288Z" fill="#73D5BE" />
            <path d="M24.0424 8.49196C26.3255 8.49196 28.1764 6.62595 28.1764 4.3241C28.1764 2.02226 26.3255 0.15625 24.0424 0.15625C21.7593 0.15625 19.9084 2.02226 19.9084 4.3241C19.9084 6.62595 21.7593 8.49196 24.0424 8.49196Z" fill="#73D5BE" />
            <path d="M23.4396 8.59962C25.7228 8.59962 27.5736 6.73361 27.5736 4.43177C27.5736 2.12993 25.7228 0.263916 23.4396 0.263916C21.1565 0.263916 19.3057 2.12993 19.3057 4.43177C19.3057 6.73361 21.1565 8.59962 23.4396 8.59962Z" fill="#73D5BE" />
            <path d="M22.8539 8.76271C25.1371 8.76271 26.9879 6.8967 26.9879 4.59486C26.9879 2.29301 25.1371 0.427002 22.8539 0.427002C20.5708 0.427002 18.72 2.29301 18.72 4.59486C18.72 6.8967 20.5708 8.76271 22.8539 8.76271Z" fill="#73D5BD" />
            <path d="M22.2924 8.97121C24.5755 8.97121 26.4264 7.10519 26.4264 4.80335C26.4264 2.50151 24.5755 0.635498 22.2924 0.635498C20.0093 0.635498 18.1584 2.50151 18.1584 4.80335C18.1584 7.10519 20.0093 8.97121 22.2924 8.97121Z" fill="#73D5BD" />
            <path d="M21.7585 9.2317C24.0416 9.2317 25.8925 7.36569 25.8925 5.06385C25.8925 2.76201 24.0416 0.895996 21.7585 0.895996C19.4754 0.895996 17.6245 2.76201 17.6245 5.06385C17.6245 7.36569 19.4754 9.2317 21.7585 9.2317Z" fill="#73D5BD" />
            <path d="M21.2451 9.53053C23.5282 9.53053 25.379 7.66452 25.379 5.36268C25.379 3.06084 23.5282 1.19482 21.2451 1.19482C18.9619 1.19482 17.1111 3.06084 17.1111 5.36268C17.1111 7.66452 18.9619 9.53053 21.2451 9.53053Z" fill="#73D5BD" />
            <path d="M20.7663 9.87428C23.0494 9.87428 24.9003 8.00827 24.9003 5.70643C24.9003 3.40459 23.0494 1.53857 20.7663 1.53857C18.4832 1.53857 16.6323 3.40459 16.6323 5.70643C16.6323 8.00827 18.4832 9.87428 20.7663 9.87428Z" fill="#73D5BC" />
            <path d="M20.3151 10.2564C22.5983 10.2564 24.4491 8.39035 24.4491 6.08851C24.4491 3.78667 22.5983 1.92065 20.3151 1.92065C18.032 1.92065 16.1812 3.78667 16.1812 6.08851C16.1812 8.39035 18.032 10.2564 20.3151 10.2564Z" fill="#73D5BC" />
            <path d="M19.9015 10.6765C22.1847 10.6765 24.0355 8.81052 24.0355 6.50867C24.0355 4.20683 22.1847 2.34082 19.9015 2.34082C17.6184 2.34082 15.7676 4.20683 15.7676 6.50867C15.7676 8.81052 17.6184 10.6765 19.9015 10.6765Z" fill="#73D5BC" />
            <path d="M19.5226 11.1282C21.8058 11.1282 23.6566 9.26218 23.6566 6.96033C23.6566 4.65849 21.8058 2.79248 19.5226 2.79248C17.2395 2.79248 15.3887 4.65849 15.3887 6.96033C15.3887 9.26218 17.2395 11.1282 19.5226 11.1282Z" fill="#73D5BB" />
            <path d="M19.1816 11.6143C21.4647 11.6143 23.3155 9.74826 23.3155 7.44642C23.3155 5.14458 21.4647 3.27856 19.1816 3.27856C16.8984 3.27856 15.0476 5.14458 15.0476 7.44642C15.0476 9.74826 16.8984 11.6143 19.1816 11.6143Z" fill="#73D5BB" />
            <path d="M18.882 12.1284C21.1651 12.1284 23.016 10.2624 23.016 7.96058C23.016 5.65874 21.1651 3.79272 18.882 3.79272C16.5989 3.79272 14.748 5.65874 14.748 7.96058C14.748 10.2624 16.5989 12.1284 18.882 12.1284Z" fill="#73D5BB" />
            <path d="M18.6269 12.6702C20.91 12.6702 22.7609 10.8042 22.7609 8.50233C22.7609 6.20048 20.91 4.33447 18.6269 4.33447C16.3438 4.33447 14.4929 6.20048 14.4929 8.50233C14.4929 10.8042 16.3438 12.6702 18.6269 12.6702Z" fill="#73D5BB" />
            <path d="M18.4169 13.2363C20.7001 13.2363 22.5509 11.3703 22.5509 9.06849C22.5509 6.76665 20.7001 4.90063 18.4169 4.90063C16.1338 4.90063 14.283 6.76665 14.283 9.06849C14.283 11.3703 16.1338 13.2363 18.4169 13.2363Z" fill="#73D5BA" />
            <path d="M18.2585 13.8233C20.5416 13.8233 22.3925 11.9572 22.3925 9.6554C22.3925 7.35356 20.5416 5.48755 18.2585 5.48755C15.9754 5.48755 14.1245 7.35356 14.1245 9.6554C14.1245 11.9572 15.9754 13.8233 18.2585 13.8233Z" fill="#73D5BA" />
            <path d="M18.1481 14.4312C20.4313 14.4312 22.2821 12.5652 22.2821 10.2633C22.2821 7.96147 20.4313 6.09546 18.1481 6.09546C15.865 6.09546 14.0142 7.96147 14.0142 10.2633C14.0142 12.5652 15.865 14.4312 18.1481 14.4312Z" fill="#73D5BA" />
            <path d="M18.093 15.0564C20.3761 15.0564 22.2269 13.1904 22.2269 10.8886C22.2269 8.58671 20.3761 6.7207 18.093 6.7207C15.8098 6.7207 13.959 8.58671 13.959 10.8886C13.959 13.1904 15.8098 15.0564 18.093 15.0564Z" fill="#73D5B9" />
            <path d="M18.0861 15.678C20.3692 15.678 22.2201 13.812 22.2201 11.5101C22.2201 9.2083 20.3692 7.34229 18.0861 7.34229C15.803 7.34229 13.9521 9.2083 13.9521 11.5101C13.9521 13.812 15.803 15.678 18.0861 15.678Z" fill="#73D5B9" />
            <path d="M18.0861 16.2822C20.3692 16.2822 22.2201 14.4162 22.2201 12.1144C22.2201 9.81255 20.3692 7.94653 18.0861 7.94653C15.803 7.94653 13.9521 9.81255 13.9521 12.1144C13.9521 14.4162 15.803 16.2822 18.0861 16.2822Z" fill="#73D5B9" />
            <path d="M18.0861 16.8902C20.3692 16.8902 22.2201 15.0241 22.2201 12.7223C22.2201 10.4205 20.3692 8.55444 18.0861 8.55444C15.803 8.55444 13.9521 10.4205 13.9521 12.7223C13.9521 15.0241 15.803 16.8902 18.0861 16.8902Z" fill="#73D5B9" />
            <path d="M18.0861 17.4944C20.3692 17.4944 22.2201 15.6284 22.2201 13.3265C22.2201 11.0247 20.3692 9.15869 18.0861 9.15869C15.803 9.15869 13.9521 11.0247 13.9521 13.3265C13.9521 15.6284 15.803 17.4944 18.0861 17.4944Z" fill="#73D5B8" />
            <path d="M18.0861 18.1023C20.3692 18.1023 22.2201 16.2363 22.2201 13.9345C22.2201 11.6326 20.3692 9.7666 18.0861 9.7666C15.803 9.7666 13.9521 11.6326 13.9521 13.9345C13.9521 16.2363 15.803 18.1023 18.0861 18.1023Z" fill="#73D5B8" />
            <path d="M18.0861 18.7102C20.3692 18.7102 22.2201 16.8442 22.2201 14.5424C22.2201 12.2405 20.3692 10.3745 18.0861 10.3745C15.803 10.3745 13.9521 12.2405 13.9521 14.5424C13.9521 16.8442 15.803 18.7102 18.0861 18.7102Z" fill="#73D5B8" />
            <path d="M18.0861 19.3145C20.3692 19.3145 22.2201 17.4485 22.2201 15.1466C22.2201 12.8448 20.3692 10.9788 18.0861 10.9788C15.803 10.9788 13.9521 12.8448 13.9521 15.1466C13.9521 17.4485 15.803 19.3145 18.0861 19.3145Z" fill="#73D5B7" />
            <path d="M18.0861 19.9224C20.3692 19.9224 22.2201 18.0564 22.2201 15.7545C22.2201 13.4527 20.3692 11.5867 18.0861 11.5867C15.803 11.5867 13.9521 13.4527 13.9521 15.7545C13.9521 18.0564 15.803 19.9224 18.0861 19.9224Z" fill="#73D5B7" />
            <path d="M18.0861 20.5266C20.3692 20.5266 22.2201 18.6606 22.2201 16.3588C22.2201 14.0569 20.3692 12.1909 18.0861 12.1909C15.803 12.1909 13.9521 14.0569 13.9521 16.3588C13.9521 18.6606 15.803 20.5266 18.0861 20.5266Z" fill="#73D5B7" />
            <path d="M18.0861 21.1343C20.3692 21.1343 22.2201 19.2683 22.2201 16.9664C22.2201 14.6646 20.3692 12.7986 18.0861 12.7986C15.803 12.7986 13.9521 14.6646 13.9521 16.9664C13.9521 19.2683 15.803 21.1343 18.0861 21.1343Z" fill="#73D5B7" />
            <path d="M18.0861 21.7388C20.3692 21.7388 22.2201 19.8728 22.2201 17.5709C22.2201 15.2691 20.3692 13.4031 18.0861 13.4031C15.803 13.4031 13.9521 15.2691 13.9521 17.5709C13.9521 19.8728 15.803 21.7388 18.0861 21.7388Z" fill="#73D5B6" />
            <path d="M18.0861 22.3464C20.3692 22.3464 22.2201 20.4804 22.2201 18.1786C22.2201 15.8768 20.3692 14.0107 18.0861 14.0107C15.803 14.0107 13.9521 15.8768 13.9521 18.1786C13.9521 20.4804 15.803 22.3464 18.0861 22.3464Z" fill="#73D5B6" />
            <path d="M18.0861 22.9544C20.3692 22.9544 22.2201 21.0883 22.2201 18.7865C22.2201 16.4847 20.3692 14.6187 18.0861 14.6187C15.803 14.6187 13.9521 16.4847 13.9521 18.7865C13.9521 21.0883 15.803 22.9544 18.0861 22.9544Z" fill="#73D5B6" />
            <path d="M18.0861 23.5586C20.3692 23.5586 22.2201 21.6926 22.2201 19.3908C22.2201 17.0889 20.3692 15.2229 18.0861 15.2229C15.803 15.2229 13.9521 17.0889 13.9521 19.3908C13.9521 21.6926 15.803 23.5586 18.0861 23.5586Z" fill="#73D5B5" />
            <path d="M18.0861 24.1665C20.3692 24.1665 22.2201 22.3005 22.2201 19.9987C22.2201 17.6968 20.3692 15.8308 18.0861 15.8308C15.803 15.8308 13.9521 17.6968 13.9521 19.9987C13.9521 22.3005 15.803 24.1665 18.0861 24.1665Z" fill="#73D5B5" />
            <path d="M18.0861 24.7708C20.3692 24.7708 22.2201 22.9048 22.2201 20.6029C22.2201 18.3011 20.3692 16.4351 18.0861 16.4351C15.803 16.4351 13.9521 18.3011 13.9521 20.6029C13.9521 22.9048 15.803 24.7708 18.0861 24.7708Z" fill="#73D5B5" />
            <path d="M18.0861 25.3787C20.3692 25.3787 22.2201 23.5127 22.2201 21.2108C22.2201 18.909 20.3692 17.043 18.0861 17.043C15.803 17.043 13.9521 18.909 13.9521 21.2108C13.9521 23.5127 15.803 25.3787 18.0861 25.3787Z" fill="#73D5B5" />
            <path d="M18.0861 25.9829C20.3692 25.9829 22.2201 24.1169 22.2201 21.8151C22.2201 19.5132 20.3692 17.6472 18.0861 17.6472C15.803 17.6472 13.9521 19.5132 13.9521 21.8151C13.9521 24.1169 15.803 25.9829 18.0861 25.9829Z" fill="#73D5B4" />
            <path d="M18.0861 26.5908C20.3692 26.5908 22.2201 24.7248 22.2201 22.423C22.2201 20.1211 20.3692 18.2551 18.0861 18.2551C15.803 18.2551 13.9521 20.1211 13.9521 22.423C13.9521 24.7248 15.803 26.5908 18.0861 26.5908Z" fill="#73D5B4" />
            <path d="M18.0861 27.1987C20.3692 27.1987 22.2201 25.3327 22.2201 23.0309C22.2201 20.729 20.3692 18.863 18.0861 18.863C15.803 18.863 13.9521 20.729 13.9521 23.0309C13.9521 25.3327 15.803 27.1987 18.0861 27.1987Z" fill="#73D5B4" />
            <path d="M18.0861 27.803C20.3692 27.803 22.2201 25.937 22.2201 23.6351C22.2201 21.3333 20.3692 19.4673 18.0861 19.4673C15.803 19.4673 13.9521 21.3333 13.9521 23.6351C13.9521 25.937 15.803 27.803 18.0861 27.803Z" fill="#73D5B3" />
            <path d="M18.0861 28.4109C20.3692 28.4109 22.2201 26.5449 22.2201 24.2431C22.2201 21.9412 20.3692 20.0752 18.0861 20.0752C15.803 20.0752 13.9521 21.9412 13.9521 24.2431C13.9521 26.5449 15.803 28.4109 18.0861 28.4109Z" fill="#73D5B3" />
            <path d="M18.0861 29.0152C20.3692 29.0152 22.2201 27.1491 22.2201 24.8473C22.2201 22.5455 20.3692 20.6794 18.0861 20.6794C15.803 20.6794 13.9521 22.5455 13.9521 24.8473C13.9521 27.1491 15.803 29.0152 18.0861 29.0152Z" fill="#73D5B3" />
            <path d="M18.0861 29.6228C20.3692 29.6228 22.2201 27.7568 22.2201 25.455C22.2201 23.1531 20.3692 21.2871 18.0861 21.2871C15.803 21.2871 13.9521 23.1531 13.9521 25.455C13.9521 27.7568 15.803 29.6228 18.0861 29.6228Z" fill="#73D5B3" />
            <path d="M18.0861 30.2273C20.3692 30.2273 22.2201 28.3613 22.2201 26.0595C22.2201 23.7576 20.3692 21.8916 18.0861 21.8916C15.803 21.8916 13.9521 23.7576 13.9521 26.0595C13.9521 28.3613 15.803 30.2273 18.0861 30.2273Z" fill="#73D5B2" />
            <path d="M18.0861 30.835C20.3692 30.835 22.2201 28.969 22.2201 26.6671C22.2201 24.3653 20.3692 22.4993 18.0861 22.4993C15.803 22.4993 13.9521 24.3653 13.9521 26.6671C13.9521 28.969 15.803 30.835 18.0861 30.835Z" fill="#73D5B2" />
            <path d="M18.0861 31.4429C20.3692 31.4429 22.2201 29.5769 22.2201 27.275C22.2201 24.9732 20.3692 23.1072 18.0861 23.1072C15.803 23.1072 13.9521 24.9732 13.9521 27.275C13.9521 29.5769 15.803 31.4429 18.0861 31.4429Z" fill="#73D5B2" />
            <path d="M18.0861 32.0471C20.3692 32.0471 22.2201 30.1811 22.2201 27.8793C22.2201 25.5774 20.3692 23.7114 18.0861 23.7114C15.803 23.7114 13.9521 25.5774 13.9521 27.8793C13.9521 30.1811 15.803 32.0471 18.0861 32.0471Z" fill="#73D5B1" />
            <path d="M18.0861 32.655C20.3692 32.655 22.2201 30.789 22.2201 28.4872C22.2201 26.1853 20.3692 24.3193 18.0861 24.3193C15.803 24.3193 13.9521 26.1853 13.9521 28.4872C13.9521 30.789 15.803 32.655 18.0861 32.655Z" fill="#73D5B1" />
            <path d="M18.0861 33.2593C20.3692 33.2593 22.2201 31.3933 22.2201 29.0914C22.2201 26.7896 20.3692 24.9236 18.0861 24.9236C15.803 24.9236 13.9521 26.7896 13.9521 29.0914C13.9521 31.3933 15.803 33.2593 18.0861 33.2593Z" fill="#73D5B1" />
            <path d="M18.0861 33.8672C20.3692 33.8672 22.2201 32.0012 22.2201 29.6993C22.2201 27.3975 20.3692 25.5315 18.0861 25.5315C15.803 25.5315 13.9521 27.3975 13.9521 29.6993C13.9521 32.0012 15.803 33.8672 18.0861 33.8672Z" fill="#73D5B1" />
            <path d="M18.0861 34.4714C20.3692 34.4714 22.2201 32.6054 22.2201 30.3036C22.2201 28.0018 20.3692 26.1357 18.0861 26.1357C15.803 26.1357 13.9521 28.0018 13.9521 30.3036C13.9521 32.6054 15.803 34.4714 18.0861 34.4714Z" fill="#73D5B0" />
            <path d="M18.0861 35.0794C20.3692 35.0794 22.2201 33.2133 22.2201 30.9115C22.2201 28.6097 20.3692 26.7437 18.0861 26.7437C15.803 26.7437 13.9521 28.6097 13.9521 30.9115C13.9521 33.2133 15.803 35.0794 18.0861 35.0794Z" fill="#73D5B0" />
            <path d="M18.0861 35.1629C20.3692 35.1629 22.2201 33.2968 22.2201 30.995C22.2201 28.6932 20.3692 26.8271 18.0861 26.8271C15.803 26.8271 13.9521 28.6932 13.9521 30.995C13.9521 33.2968 15.803 35.1629 18.0861 35.1629Z" fill="#73D6CD" />
            <path d="M18.0861 34.5549C20.3692 34.5549 22.2201 32.6889 22.2201 30.3871C22.2201 28.0853 20.3692 26.2192 18.0861 26.2192C15.803 26.2192 13.9521 28.0853 13.9521 30.3871C13.9521 32.6889 15.803 34.5549 18.0861 34.5549Z" fill="#73D6CD" />
            <path d="M18.0861 33.9507C20.3692 33.9507 22.2201 32.0847 22.2201 29.7828C22.2201 27.481 20.3692 25.615 18.0861 25.615C15.803 25.615 13.9521 27.481 13.9521 29.7828C13.9521 32.0847 15.803 33.9507 18.0861 33.9507Z" fill="#73D6CC" />
            <path d="M18.0861 33.3428C20.3692 33.3428 22.2201 31.4768 22.2201 29.1749C22.2201 26.8731 20.3692 25.0071 18.0861 25.0071C15.803 25.0071 13.9521 26.8731 13.9521 29.1749C13.9521 31.4768 15.803 33.3428 18.0861 33.3428Z" fill="#73D6CC" />
            <path d="M18.0861 32.7351C20.3692 32.7351 22.2201 30.8691 22.2201 28.5673C22.2201 26.2654 20.3692 24.3994 18.0861 24.3994C15.803 24.3994 13.9521 26.2654 13.9521 28.5673C13.9521 30.8691 15.803 32.7351 18.0861 32.7351Z" fill="#73D6CC" />
            <path d="M18.0861 32.1306C20.3692 32.1306 22.2201 30.2646 22.2201 27.9628C22.2201 25.6609 20.3692 23.7949 18.0861 23.7949C15.803 23.7949 13.9521 25.6609 13.9521 27.9628C13.9521 30.2646 15.803 32.1306 18.0861 32.1306Z" fill="#73D6CC" />
            <path d="M18.0861 31.523C20.3692 31.523 22.2201 29.657 22.2201 27.3551C22.2201 25.0533 20.3692 23.1873 18.0861 23.1873C15.803 23.1873 13.9521 25.0533 13.9521 27.3551C13.9521 29.657 15.803 31.523 18.0861 31.523Z" fill="#73D6CB" />
            <path d="M18.0861 30.9185C20.3692 30.9185 22.2201 29.0525 22.2201 26.7506C22.2201 24.4488 20.3692 22.5828 18.0861 22.5828C15.803 22.5828 13.9521 24.4488 13.9521 26.7506C13.9521 29.0525 15.803 30.9185 18.0861 30.9185Z" fill="#73D6CB" />
            <path d="M18.0861 30.3108C20.3692 30.3108 22.2201 28.4448 22.2201 26.1429C22.2201 23.8411 20.3692 21.9751 18.0861 21.9751C15.803 21.9751 13.9521 23.8411 13.9521 26.1429C13.9521 28.4448 15.803 30.3108 18.0861 30.3108Z" fill="#73D6CB" />
            <path d="M18.0861 29.7063C20.3692 29.7063 22.2201 27.8403 22.2201 25.5385C22.2201 23.2366 20.3692 21.3706 18.0861 21.3706C15.803 21.3706 13.9521 23.2366 13.9521 25.5385C13.9521 27.8403 15.803 29.7063 18.0861 29.7063Z" fill="#73D6CA" />
            <path d="M18.0861 29.0986C20.3692 29.0986 22.2201 27.2326 22.2201 24.9308C22.2201 22.629 20.3692 20.7629 18.0861 20.7629C15.803 20.7629 13.9521 22.629 13.9521 24.9308C13.9521 27.2326 15.803 29.0986 18.0861 29.0986Z" fill="#73D6CA" />
            <path d="M18.0861 28.4907C20.3692 28.4907 22.2201 26.6247 22.2201 24.3229C22.2201 22.021 20.3692 20.155 18.0861 20.155C15.803 20.155 13.9521 22.021 13.9521 24.3229C13.9521 26.6247 15.803 28.4907 18.0861 28.4907Z" fill="#73D6CA" />
            <path d="M18.0861 27.8865C20.3692 27.8865 22.2201 26.0205 22.2201 23.7186C22.2201 21.4168 20.3692 19.5508 18.0861 19.5508C15.803 19.5508 13.9521 21.4168 13.9521 23.7186C13.9521 26.0205 15.803 27.8865 18.0861 27.8865Z" fill="#73D6CA" />
            <path d="M18.0861 27.2786C20.3692 27.2786 22.2201 25.4126 22.2201 23.1107C22.2201 20.8089 20.3692 18.9429 18.0861 18.9429C15.803 18.9429 13.9521 20.8089 13.9521 23.1107C13.9521 25.4126 15.803 27.2786 18.0861 27.2786Z" fill="#73D6C9" />
            <path d="M18.0861 26.6743C20.3692 26.6743 22.2201 24.8083 22.2201 22.5065C22.2201 20.2046 20.3692 18.3386 18.0861 18.3386C15.803 18.3386 13.9521 20.2046 13.9521 22.5065C13.9521 24.8083 15.803 26.6743 18.0861 26.6743Z" fill="#73D6C9" />
            <path d="M18.0861 26.0664C20.3692 26.0664 22.2201 24.2004 22.2201 21.8986C22.2201 19.5967 20.3692 17.7307 18.0861 17.7307C15.803 17.7307 13.9521 19.5967 13.9521 21.8986C13.9521 24.2004 15.803 26.0664 18.0861 26.0664Z" fill="#73D6C9" />
            <path d="M18.0861 25.4622C20.3692 25.4622 22.2201 23.5962 22.2201 21.2943C22.2201 18.9925 20.3692 17.1265 18.0861 17.1265C15.803 17.1265 13.9521 18.9925 13.9521 21.2943C13.9521 23.5962 15.803 25.4622 18.0861 25.4622Z" fill="#73D6C8" />
            <path d="M18.0861 24.8543C20.3692 24.8543 22.2201 22.9883 22.2201 20.6864C22.2201 18.3846 20.3692 16.5186 18.0861 16.5186C15.803 16.5186 13.9521 18.3846 13.9521 20.6864C13.9521 22.9883 15.803 24.8543 18.0861 24.8543Z" fill="#73D6C8" />
            <path d="M18.0861 24.2466C20.3692 24.2466 22.2201 22.3806 22.2201 20.0787C22.2201 17.7769 20.3692 15.9109 18.0861 15.9109C15.803 15.9109 13.9521 17.7769 13.9521 20.0787C13.9521 22.3806 15.803 24.2466 18.0861 24.2466Z" fill="#73D6C8" />
            <path d="M18.0861 23.6421C20.3692 23.6421 22.2201 21.7761 22.2201 19.4743C22.2201 17.1724 20.3692 15.3064 18.0861 15.3064C15.803 15.3064 13.9521 17.1724 13.9521 19.4743C13.9521 21.7761 15.803 23.6421 18.0861 23.6421Z" fill="#73D6C8" />
            <path d="M18.0861 23.0344C20.3692 23.0344 22.2201 21.1684 22.2201 18.8666C22.2201 16.5647 20.3692 14.6987 18.0861 14.6987C15.803 14.6987 13.9521 16.5647 13.9521 18.8666C13.9521 21.1684 15.803 23.0344 18.0861 23.0344Z" fill="#73D6C7" />
            <path d="M18.0861 22.4299C20.3692 22.4299 22.2201 20.5639 22.2201 18.2621C22.2201 15.9603 20.3692 14.0942 18.0861 14.0942C15.803 14.0942 13.9521 15.9603 13.9521 18.2621C13.9521 20.5639 15.803 22.4299 18.0861 22.4299Z" fill="#73D6C7" />
            <path d="M18.0861 21.8223C20.3692 21.8223 22.2201 19.9563 22.2201 17.6544C22.2201 15.3526 20.3692 13.4866 18.0861 13.4866C15.803 13.4866 13.9521 15.3526 13.9521 17.6544C13.9521 19.9563 15.803 21.8223 18.0861 21.8223Z" fill="#73D6C7" />
            <path d="M18.0861 21.2178C20.3692 21.2178 22.2201 19.3518 22.2201 17.0499C22.2201 14.7481 20.3692 12.8821 18.0861 12.8821C15.803 12.8821 13.9521 14.7481 13.9521 17.0499C13.9521 19.3518 15.803 21.2178 18.0861 21.2178Z" fill="#73D6C6" />
            <path d="M18.0861 20.6101C20.3692 20.6101 22.2201 18.7441 22.2201 16.4423C22.2201 14.1404 20.3692 12.2744 18.0861 12.2744C15.803 12.2744 13.9521 14.1404 13.9521 16.4423C13.9521 18.7441 15.803 20.6101 18.0861 20.6101Z" fill="#73D6C6" />
            <path d="M18.0861 20.0022C20.3692 20.0022 22.2201 18.1362 22.2201 15.8344C22.2201 13.5325 20.3692 11.6665 18.0861 11.6665C15.803 11.6665 13.9521 13.5325 13.9521 15.8344C13.9521 18.1362 15.803 20.0022 18.0861 20.0022Z" fill="#73D6C6" />
            <path d="M18.0861 19.398C20.3692 19.398 22.2201 17.532 22.2201 15.2301C22.2201 12.9283 20.3692 11.0623 18.0861 11.0623C15.803 11.0623 13.9521 12.9283 13.9521 15.2301C13.9521 17.532 15.803 19.398 18.0861 19.398Z" fill="#73D6C6" />
            <path d="M18.0861 18.7901C20.3692 18.7901 22.2201 16.924 22.2201 14.6222C22.2201 12.3204 20.3692 10.4543 18.0861 10.4543C15.803 10.4543 13.9521 12.3204 13.9521 14.6222C13.9521 16.924 15.803 18.7901 18.0861 18.7901Z" fill="#73D6C5" />
            <path d="M18.0861 18.1858C20.3692 18.1858 22.2201 16.3198 22.2201 14.018C22.2201 11.7161 20.3692 9.8501 18.0861 9.8501C15.803 9.8501 13.9521 11.7161 13.9521 14.018C13.9521 16.3198 15.803 18.1858 18.0861 18.1858Z" fill="#73D6C5" />
            <path d="M18.0861 17.5779C20.3692 17.5779 22.2201 15.7119 22.2201 13.41C22.2201 11.1082 20.3692 9.24219 18.0861 9.24219C15.803 9.24219 13.9521 11.1082 13.9521 13.41C13.9521 15.7119 15.803 17.5779 18.0861 17.5779Z" fill="#73D6C5" />
            <path d="M18.0861 16.9736C20.3692 16.9736 22.2201 15.1076 22.2201 12.8058C22.2201 10.504 20.3692 8.63794 18.0861 8.63794C15.803 8.63794 13.9521 10.504 13.9521 12.8058C13.9521 15.1076 15.803 16.9736 18.0861 16.9736Z" fill="#73D6C4" />
            <path d="M18.0861 16.3657C20.3692 16.3657 22.2201 14.4997 22.2201 12.1979C22.2201 9.89604 20.3692 8.03003 18.0861 8.03003C15.803 8.03003 13.9521 9.89604 13.9521 12.1979C13.9521 14.4997 15.803 16.3657 18.0861 16.3657Z" fill="#73D6C4" />
            <path d="M18.0861 15.7615C20.3692 15.7615 22.2201 13.8955 22.2201 11.5936C22.2201 9.29179 20.3692 7.42578 18.0861 7.42578C15.803 7.42578 13.9521 9.29179 13.9521 11.5936C13.9521 13.8955 15.803 15.7615 18.0861 15.7615Z" fill="#73D6C4" />
            <path d="M18.0793 15.1399C20.3624 15.1399 22.2133 13.2739 22.2133 10.9721C22.2133 8.67021 20.3624 6.8042 18.0793 6.8042C15.7962 6.8042 13.9453 8.67021 13.9453 10.9721C13.9453 13.2739 15.7962 15.1399 18.0793 15.1399Z" fill="#73D6C4" />
            <path d="M18.0241 14.5147C20.3072 14.5147 22.1581 12.6487 22.1581 10.3468C22.1581 8.04497 20.3072 6.17896 18.0241 6.17896C15.741 6.17896 13.8901 8.04497 13.8901 10.3468C13.8901 12.6487 15.741 14.5147 18.0241 14.5147Z" fill="#73D6C3" />
            <path d="M17.914 13.9068C20.1971 13.9068 22.048 12.0407 22.048 9.7389C22.048 7.43706 20.1971 5.57104 17.914 5.57104C15.6309 5.57104 13.78 7.43706 13.78 9.7389C13.78 12.0407 15.6309 13.9068 17.914 13.9068Z" fill="#73D6C3" />
            <path d="M17.7556 13.3198C20.0387 13.3198 21.8895 11.4538 21.8895 9.15198C21.8895 6.85014 20.0387 4.98413 17.7556 4.98413C15.4724 4.98413 13.6216 6.85014 13.6216 9.15198C13.6216 11.4538 15.4724 13.3198 17.7556 13.3198Z" fill="#73D6C3" />
            <path d="M17.5453 12.7537C19.8285 12.7537 21.6793 10.8877 21.6793 8.58582C21.6793 6.28398 19.8285 4.41797 17.5453 4.41797C15.2622 4.41797 13.4114 6.28398 13.4114 8.58582C13.4114 10.8877 15.2622 12.7537 17.5453 12.7537Z" fill="#73D6C2" />
            <path d="M17.2905 12.2119C19.5736 12.2119 21.4244 10.3459 21.4244 8.04407C21.4244 5.74223 19.5736 3.87622 17.2905 3.87622C15.0073 3.87622 13.1565 5.74223 13.1565 8.04407C13.1565 10.3459 15.0073 12.2119 17.2905 12.2119Z" fill="#73D6C2" />
            <path d="M16.9907 11.6978C19.2738 11.6978 21.1246 9.83176 21.1246 7.52991C21.1246 5.22807 19.2738 3.36206 16.9907 3.36206C14.7075 3.36206 12.8567 5.22807 12.8567 7.52991C12.8567 9.83176 14.7075 11.6978 16.9907 11.6978Z" fill="#73D6C2" />
            <path d="M16.6496 11.2117C18.9327 11.2117 20.7836 9.34567 20.7836 7.04383C20.7836 4.74199 18.9327 2.87598 16.6496 2.87598C14.3665 2.87598 12.5156 4.74199 12.5156 7.04383C12.5156 9.34567 14.3665 11.2117 16.6496 11.2117Z" fill="#73D6C2" />
            <path d="M16.2707 10.7566C18.5538 10.7566 20.4047 8.89059 20.4047 6.58875C20.4047 4.28691 18.5538 2.4209 16.2707 2.4209C13.9876 2.4209 12.1367 4.28691 12.1367 6.58875C12.1367 8.89059 13.9876 10.7566 16.2707 10.7566Z" fill="#73D6C1" />
            <path d="M15.8574 10.3399C18.1405 10.3399 19.9913 8.47385 19.9913 6.172C19.9913 3.87016 18.1405 2.00415 15.8574 2.00415C13.5742 2.00415 11.7234 3.87016 11.7234 6.172C11.7234 8.47385 13.5742 10.3399 15.8574 10.3399Z" fill="#73D6C1" />
            <path d="M15.4059 9.95778C17.6891 9.95778 19.5399 8.09177 19.5399 5.78992C19.5399 3.48808 17.6891 1.62207 15.4059 1.62207C13.1228 1.62207 11.272 3.48808 11.272 5.78992C11.272 8.09177 13.1228 9.95778 15.4059 9.95778Z" fill="#73D6C1" />
            <path d="M14.9272 9.61403C17.2103 9.61403 19.0612 7.74802 19.0612 5.44617C19.0612 3.14433 17.2103 1.27832 14.9272 1.27832C12.6441 1.27832 10.7932 3.14433 10.7932 5.44617C10.7932 7.74802 12.6441 9.61403 14.9272 9.61403Z" fill="#73D6C0" />
            <path d="M14.414 9.31178C16.6971 9.31178 18.548 7.44577 18.548 5.14393C18.548 2.84209 16.6971 0.976074 14.414 0.976074C12.1309 0.976074 10.28 2.84209 10.28 5.14393C10.28 7.44577 12.1309 9.31178 14.414 9.31178Z" fill="#73D6C0" />
            <path d="M13.8798 9.0547C16.163 9.0547 18.0138 7.18869 18.0138 4.88685C18.0138 2.58501 16.163 0.718994 13.8798 0.718994C11.5967 0.718994 9.74585 2.58501 9.74585 4.88685C9.74585 7.18869 11.5967 9.0547 13.8798 9.0547Z" fill="#73D6C0" />
            <path d="M13.3183 8.84621C15.6014 8.84621 17.4523 6.98019 17.4523 4.67835C17.4523 2.37651 15.6014 0.510498 13.3183 0.510498C11.0352 0.510498 9.18433 2.37651 9.18433 4.67835C9.18433 6.98019 11.0352 8.84621 13.3183 8.84621Z" fill="#73D6C0" />
            <path d="M12.7328 8.68312C15.016 8.68312 16.8668 6.81711 16.8668 4.51527C16.8668 2.21342 15.016 0.347412 12.7328 0.347412C10.4497 0.347412 8.59888 2.21342 8.59888 4.51527C8.59888 6.81711 10.4497 8.68312 12.7328 8.68312Z" fill="#73D6BF" />
            <path d="M12.1298 8.57204C14.413 8.57204 16.2638 6.70602 16.2638 4.40418C16.2638 2.10234 14.413 0.236328 12.1298 0.236328C9.84669 0.236328 7.99585 2.10234 7.99585 4.40418C7.99585 6.70602 9.84669 8.57204 12.1298 8.57204Z" fill="#73D6BF" />
            <path d="M11.5131 8.51637C13.7962 8.51637 15.6471 6.65036 15.6471 4.34852C15.6471 2.04668 13.7962 0.180664 11.5131 0.180664C9.22999 0.180664 7.37915 2.04668 7.37915 4.34852C7.37915 6.65036 9.22999 8.51637 11.5131 8.51637Z" fill="#73D6BF" />
            <path d="M10.8793 8.51637C13.1625 8.51637 15.0133 6.65036 15.0133 4.34852C15.0133 2.04668 13.1625 0.180664 10.8793 0.180664C8.5962 0.180664 6.74536 2.04668 6.74536 4.34852C6.74536 6.65036 8.5962 8.51637 10.8793 8.51637Z" fill="#73D5BE" />
            <path d="M10.2626 8.57204C12.5458 8.57204 14.3966 6.70602 14.3966 4.40418C14.3966 2.10234 12.5458 0.236328 10.2626 0.236328C7.9795 0.236328 6.12866 2.10234 6.12866 4.40418C6.12866 6.70602 7.9795 8.57204 10.2626 8.57204Z" fill="#73D5BE" />
            <path d="M9.65985 8.68312C11.943 8.68312 13.7938 6.81711 13.7938 4.51527C13.7938 2.21342 11.943 0.347412 9.65985 0.347412C7.37672 0.347412 5.52588 2.21342 5.52588 4.51527C5.52588 6.81711 7.37672 8.68312 9.65985 8.68312Z" fill="#73D5BE" />
            <path d="M9.07416 8.84621C11.3573 8.84621 13.2081 6.98019 13.2081 4.67835C13.2081 2.37651 11.3573 0.510498 9.07416 0.510498C6.79103 0.510498 4.94019 2.37651 4.94019 4.67835C4.94019 6.98019 6.79103 8.84621 9.07416 8.84621Z" fill="#73D5BD" />
            <path d="M8.51263 9.0547C10.7958 9.0547 12.6466 7.18869 12.6466 4.88685C12.6466 2.58501 10.7958 0.718994 8.51263 0.718994C6.2295 0.718994 4.37866 2.58501 4.37866 4.88685C4.37866 7.18869 6.2295 9.0547 8.51263 9.0547Z" fill="#73D5BD" />
            <path d="M7.9787 9.31178C10.2618 9.31178 12.1127 7.44577 12.1127 5.14393C12.1127 2.84209 10.2618 0.976074 7.9787 0.976074C5.69557 0.976074 3.84473 2.84209 3.84473 5.14393C3.84473 7.44577 5.69557 9.31178 7.9787 9.31178Z" fill="#73D5BD" />
            <path d="M7.46527 9.61403C9.7484 9.61403 11.5992 7.74802 11.5992 5.44617C11.5992 3.14433 9.7484 1.27832 7.46527 1.27832C5.18214 1.27832 3.3313 3.14433 3.3313 5.44617C3.3313 7.74802 5.18214 9.61403 7.46527 9.61403Z" fill="#73D5BD" />
            <path d="M6.98651 9.95778C9.26964 9.95778 11.1205 8.09177 11.1205 5.78992C11.1205 3.48808 9.26964 1.62207 6.98651 1.62207C4.70338 1.62207 2.85254 3.48808 2.85254 5.78992C2.85254 8.09177 4.70338 9.95778 6.98651 9.95778Z" fill="#73D5BC" />
            <path d="M6.53534 10.3399C8.81847 10.3399 10.6693 8.47385 10.6693 6.172C10.6693 3.87016 8.81847 2.00415 6.53534 2.00415C4.25221 2.00415 2.40137 3.87016 2.40137 6.172C2.40137 8.47385 4.25221 10.3399 6.53534 10.3399Z" fill="#73D5BC" />
            <path d="M6.12176 10.7566C8.40489 10.7566 10.2557 8.89059 10.2557 6.58875C10.2557 4.28691 8.40489 2.4209 6.12176 2.4209C3.83863 2.4209 1.98779 4.28691 1.98779 6.58875C1.98779 8.89059 3.83863 10.7566 6.12176 10.7566Z" fill="#73D5BC" />
            <path d="M5.74286 11.2117C8.02599 11.2117 9.87683 9.34567 9.87683 7.04383C9.87683 4.74199 8.02599 2.87598 5.74286 2.87598C3.45973 2.87598 1.60889 4.74199 1.60889 7.04383C1.60889 9.34567 3.45973 11.2117 5.74286 11.2117Z" fill="#73D5BB" />
            <path d="M5.40179 11.6978C7.68492 11.6978 9.53576 9.83176 9.53576 7.52991C9.53576 5.22807 7.68492 3.36206 5.40179 3.36206C3.11866 3.36206 1.26782 5.22807 1.26782 7.52991C1.26782 9.83176 3.11866 11.6978 5.40179 11.6978Z" fill="#73D5BB" />
            <path d="M5.10223 12.2119C7.38536 12.2119 9.2362 10.3459 9.2362 8.04407C9.2362 5.74223 7.38536 3.87622 5.10223 3.87622C2.8191 3.87622 0.968262 5.74223 0.968262 8.04407C0.968262 10.3459 2.8191 12.2119 5.10223 12.2119Z" fill="#73D5BB" />
            <path d="M4.84711 12.7537C7.13024 12.7537 8.98108 10.8877 8.98108 8.58582C8.98108 6.28398 7.13024 4.41797 4.84711 4.41797C2.56398 4.41797 0.713135 6.28398 0.713135 8.58582C0.713135 10.8877 2.56398 12.7537 4.84711 12.7537Z" fill="#73D5BB" />
            <path d="M4.63715 13.3198C6.92027 13.3198 8.77112 11.4538 8.77112 9.15198C8.77112 6.85014 6.92027 4.98413 4.63715 4.98413C2.35402 4.98413 0.503174 6.85014 0.503174 9.15198C0.503174 11.4538 2.35402 13.3198 4.63715 13.3198Z" fill="#73D5BA" />
            <path d="M4.4787 13.9068C6.76183 13.9068 8.61267 12.0407 8.61267 9.7389C8.61267 7.43706 6.76183 5.57104 4.4787 5.57104C2.19557 5.57104 0.344727 7.43706 0.344727 9.7389C0.344727 12.0407 2.19557 13.9068 4.4787 13.9068Z" fill="#73D5BA" />
            <path d="M4.36835 14.5147C6.65148 14.5147 8.50232 12.6487 8.50232 10.3468C8.50232 8.04497 6.65148 6.17896 4.36835 6.17896C2.08522 6.17896 0.234375 8.04497 0.234375 10.3468C0.234375 12.6487 2.08522 14.5147 4.36835 14.5147Z" fill="#73D5BA" />
            <path d="M4.31317 15.1399C6.5963 15.1399 8.44714 13.2739 8.44714 10.9721C8.44714 8.67021 6.5963 6.8042 4.31317 6.8042C2.03004 6.8042 0.179199 8.67021 0.179199 10.9721C0.179199 13.2739 2.03004 15.1399 4.31317 15.1399Z" fill="#73D5B9" />
            <path d="M4.30633 15.7615C6.58946 15.7615 8.44031 13.8955 8.44031 11.5936C8.44031 9.29179 6.58946 7.42578 4.30633 7.42578C2.02321 7.42578 0.172363 9.29179 0.172363 11.5936C0.172363 13.8955 2.02321 15.7615 4.30633 15.7615Z" fill="#73D5B9" />
            <path d="M4.30633 16.3657C6.58946 16.3657 8.44031 14.4997 8.44031 12.1979C8.44031 9.89604 6.58946 8.03003 4.30633 8.03003C2.02321 8.03003 0.172363 9.89604 0.172363 12.1979C0.172363 14.4997 2.02321 16.3657 4.30633 16.3657Z" fill="#73D5B9" />
            <path d="M4.30633 16.9736C6.58946 16.9736 8.44031 15.1076 8.44031 12.8058C8.44031 10.504 6.58946 8.63794 4.30633 8.63794C2.02321 8.63794 0.172363 10.504 0.172363 12.8058C0.172363 15.1076 2.02321 16.9736 4.30633 16.9736Z" fill="#73D5B9" />
            <path d="M4.30633 17.5779C6.58946 17.5779 8.44031 15.7119 8.44031 13.41C8.44031 11.1082 6.58946 9.24219 4.30633 9.24219C2.02321 9.24219 0.172363 11.1082 0.172363 13.41C0.172363 15.7119 2.02321 17.5779 4.30633 17.5779Z" fill="#73D5B8" />
            <path d="M4.30633 18.1858C6.58946 18.1858 8.44031 16.3198 8.44031 14.018C8.44031 11.7161 6.58946 9.8501 4.30633 9.8501C2.02321 9.8501 0.172363 11.7161 0.172363 14.018C0.172363 16.3198 2.02321 18.1858 4.30633 18.1858Z" fill="#73D5B8" />
            <path d="M4.30633 18.7901C6.58946 18.7901 8.44031 16.924 8.44031 14.6222C8.44031 12.3204 6.58946 10.4543 4.30633 10.4543C2.02321 10.4543 0.172363 12.3204 0.172363 14.6222C0.172363 16.924 2.02321 18.7901 4.30633 18.7901Z" fill="#73D5B8" />
            <path d="M4.30633 19.398C6.58946 19.398 8.44031 17.532 8.44031 15.2301C8.44031 12.9283 6.58946 11.0623 4.30633 11.0623C2.02321 11.0623 0.172363 12.9283 0.172363 15.2301C0.172363 17.532 2.02321 19.398 4.30633 19.398Z" fill="#73D5B7" />
            <path d="M4.30633 20.0022C6.58946 20.0022 8.44031 18.1362 8.44031 15.8344C8.44031 13.5325 6.58946 11.6665 4.30633 11.6665C2.02321 11.6665 0.172363 13.5325 0.172363 15.8344C0.172363 18.1362 2.02321 20.0022 4.30633 20.0022Z" fill="#73D5B7" />
            <path d="M4.30633 20.6101C6.58946 20.6101 8.44031 18.7441 8.44031 16.4423C8.44031 14.1404 6.58946 12.2744 4.30633 12.2744C2.02321 12.2744 0.172363 14.1404 0.172363 16.4423C0.172363 18.7441 2.02321 20.6101 4.30633 20.6101Z" fill="#73D5B7" />
            <path d="M4.30633 21.2178C6.58946 21.2178 8.44031 19.3518 8.44031 17.0499C8.44031 14.7481 6.58946 12.8821 4.30633 12.8821C2.02321 12.8821 0.172363 14.7481 0.172363 17.0499C0.172363 19.3518 2.02321 21.2178 4.30633 21.2178Z" fill="#73D5B7" />
            <path d="M4.30633 21.8223C6.58946 21.8223 8.44031 19.9563 8.44031 17.6544C8.44031 15.3526 6.58946 13.4866 4.30633 13.4866C2.02321 13.4866 0.172363 15.3526 0.172363 17.6544C0.172363 19.9563 2.02321 21.8223 4.30633 21.8223Z" fill="#73D5B6" />
            <path d="M4.30633 22.4299C6.58946 22.4299 8.44031 20.5639 8.44031 18.2621C8.44031 15.9603 6.58946 14.0942 4.30633 14.0942C2.02321 14.0942 0.172363 15.9603 0.172363 18.2621C0.172363 20.5639 2.02321 22.4299 4.30633 22.4299Z" fill="#73D5B6" />
            <path d="M4.30633 23.0344C6.58946 23.0344 8.44031 21.1684 8.44031 18.8666C8.44031 16.5647 6.58946 14.6987 4.30633 14.6987C2.02321 14.6987 0.172363 16.5647 0.172363 18.8666C0.172363 21.1684 2.02321 23.0344 4.30633 23.0344Z" fill="#73D5B6" />
            <path d="M4.30633 23.6421C6.58946 23.6421 8.44031 21.7761 8.44031 19.4743C8.44031 17.1724 6.58946 15.3064 4.30633 15.3064C2.02321 15.3064 0.172363 17.1724 0.172363 19.4743C0.172363 21.7761 2.02321 23.6421 4.30633 23.6421Z" fill="#73D5B5" />
            <path d="M4.30633 24.2466C6.58946 24.2466 8.44031 22.3806 8.44031 20.0787C8.44031 17.7769 6.58946 15.9109 4.30633 15.9109C2.02321 15.9109 0.172363 17.7769 0.172363 20.0787C0.172363 22.3806 2.02321 24.2466 4.30633 24.2466Z" fill="#73D5B5" />
            <path d="M4.30633 24.8543C6.58946 24.8543 8.44031 22.9883 8.44031 20.6864C8.44031 18.3846 6.58946 16.5186 4.30633 16.5186C2.02321 16.5186 0.172363 18.3846 0.172363 20.6864C0.172363 22.9883 2.02321 24.8543 4.30633 24.8543Z" fill="#73D5B5" />
            <path d="M4.30633 25.4622C6.58946 25.4622 8.44031 23.5962 8.44031 21.2943C8.44031 18.9925 6.58946 17.1265 4.30633 17.1265C2.02321 17.1265 0.172363 18.9925 0.172363 21.2943C0.172363 23.5962 2.02321 25.4622 4.30633 25.4622Z" fill="#73D5B5" />
            <path d="M4.30633 26.0664C6.58946 26.0664 8.44031 24.2004 8.44031 21.8986C8.44031 19.5967 6.58946 17.7307 4.30633 17.7307C2.02321 17.7307 0.172363 19.5967 0.172363 21.8986C0.172363 24.2004 2.02321 26.0664 4.30633 26.0664Z" fill="#73D5B4" />
            <path d="M4.30633 26.6743C6.58946 26.6743 8.44031 24.8083 8.44031 22.5065C8.44031 20.2046 6.58946 18.3386 4.30633 18.3386C2.02321 18.3386 0.172363 20.2046 0.172363 22.5065C0.172363 24.8083 2.02321 26.6743 4.30633 26.6743Z" fill="#73D5B4" />
            <path d="M4.30633 27.2786C6.58946 27.2786 8.44031 25.4126 8.44031 23.1107C8.44031 20.8089 6.58946 18.9429 4.30633 18.9429C2.02321 18.9429 0.172363 20.8089 0.172363 23.1107C0.172363 25.4126 2.02321 27.2786 4.30633 27.2786Z" fill="#73D5B4" />
            <path d="M4.30633 27.8865C6.58946 27.8865 8.44031 26.0205 8.44031 23.7186C8.44031 21.4168 6.58946 19.5508 4.30633 19.5508C2.02321 19.5508 0.172363 21.4168 0.172363 23.7186C0.172363 26.0205 2.02321 27.8865 4.30633 27.8865Z" fill="#73D5B3" />
            <path d="M4.30633 28.4907C6.58946 28.4907 8.44031 26.6247 8.44031 24.3229C8.44031 22.021 6.58946 20.155 4.30633 20.155C2.02321 20.155 0.172363 22.021 0.172363 24.3229C0.172363 26.6247 2.02321 28.4907 4.30633 28.4907Z" fill="#73D5B3" />
            <path d="M4.30633 29.0986C6.58946 29.0986 8.44031 27.2326 8.44031 24.9308C8.44031 22.629 6.58946 20.7629 4.30633 20.7629C2.02321 20.7629 0.172363 22.629 0.172363 24.9308C0.172363 27.2326 2.02321 29.0986 4.30633 29.0986Z" fill="#73D5B3" />
            <path d="M4.30633 29.7063C6.58946 29.7063 8.44031 27.8403 8.44031 25.5385C8.44031 23.2366 6.58946 21.3706 4.30633 21.3706C2.02321 21.3706 0.172363 23.2366 0.172363 25.5385C0.172363 27.8403 2.02321 29.7063 4.30633 29.7063Z" fill="#73D5B3" />
            <path d="M4.30633 30.3108C6.58946 30.3108 8.44031 28.4448 8.44031 26.1429C8.44031 23.8411 6.58946 21.9751 4.30633 21.9751C2.02321 21.9751 0.172363 23.8411 0.172363 26.1429C0.172363 28.4448 2.02321 30.3108 4.30633 30.3108Z" fill="#73D5B2" />
            <path d="M4.30633 30.9185C6.58946 30.9185 8.44031 29.0525 8.44031 26.7506C8.44031 24.4488 6.58946 22.5828 4.30633 22.5828C2.02321 22.5828 0.172363 24.4488 0.172363 26.7506C0.172363 29.0525 2.02321 30.9185 4.30633 30.9185Z" fill="#73D5B2" />
            <path d="M4.30633 31.523C6.58946 31.523 8.44031 29.657 8.44031 27.3551C8.44031 25.0533 6.58946 23.1873 4.30633 23.1873C2.02321 23.1873 0.172363 25.0533 0.172363 27.3551C0.172363 29.657 2.02321 31.523 4.30633 31.523Z" fill="#73D5B2" />
            <path d="M4.30633 32.1306C6.58946 32.1306 8.44031 30.2646 8.44031 27.9628C8.44031 25.6609 6.58946 23.7949 4.30633 23.7949C2.02321 23.7949 0.172363 25.6609 0.172363 27.9628C0.172363 30.2646 2.02321 32.1306 4.30633 32.1306Z" fill="#73D5B1" />
            <path d="M4.30633 32.7351C6.58946 32.7351 8.44031 30.8691 8.44031 28.5673C8.44031 26.2654 6.58946 24.3994 4.30633 24.3994C2.02321 24.3994 0.172363 26.2654 0.172363 28.5673C0.172363 30.8691 2.02321 32.7351 4.30633 32.7351Z" fill="#73D5B1" />
            <path d="M4.30633 33.3428C6.58946 33.3428 8.44031 31.4768 8.44031 29.1749C8.44031 26.8731 6.58946 25.0071 4.30633 25.0071C2.02321 25.0071 0.172363 26.8731 0.172363 29.1749C0.172363 31.4768 2.02321 33.3428 4.30633 33.3428Z" fill="#73D5B1" />
            <path d="M4.30633 33.9507C6.58946 33.9507 8.44031 32.0847 8.44031 29.7828C8.44031 27.481 6.58946 25.615 4.30633 25.615C2.02321 25.615 0.172363 27.481 0.172363 29.7828C0.172363 32.0847 2.02321 33.9507 4.30633 33.9507Z" fill="#73D5B1" />
            <path d="M4.30633 34.5549C6.58946 34.5549 8.44031 32.6889 8.44031 30.3871C8.44031 28.0853 6.58946 26.2192 4.30633 26.2192C2.02321 26.2192 0.172363 28.0853 0.172363 30.3871C0.172363 32.6889 2.02321 34.5549 4.30633 34.5549Z" fill="#73D5B0" />
            <path d="M4.30633 35.1629C6.58946 35.1629 8.44031 33.2968 8.44031 30.995C8.44031 28.6932 6.58946 26.8271 4.30633 26.8271C2.02321 26.8271 0.172363 28.6932 0.172363 30.995C0.172363 33.2968 2.02321 35.1629 4.30633 35.1629Z" fill="#73D5B0" />
          </g>
          <defs>
            <clipPath id="clip0_135_4944">
              <rect width="36" height="35.1628" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <BottomBar />
      <Inputer />
      <ColorPicker />
      <Flow />
    </div>
  );
}

export default App;
